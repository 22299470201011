/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import axios, { AxiosRequestConfig } from 'axios';

import ThenArg from 'helpers/ThenArg';

import API_private from './types/api-private';
import API_public from './types/api-public';
import API_uploadService from './types/api-upload-service';
import { apmElastic } from 'helpers/apmElastic';
import API_private_deprecated from './types/api-deprecated';

let frontHost: 'production'|'development';
const seemsQA = /\bqa\b.*\.dielenergia\.com$/.test(window.location.host);
if (seemsQA) { frontHost = 'development'; }
else if (window.location.host.includes('dev-nav')) { frontHost = 'development'; }
else if (window.location.host.includes('-demo')) { frontHost = 'development'; }
else if (window.location.host.endsWith('.dielenergia.com')) { frontHost = 'production'; }
else { frontHost = 'development'; }

// localStorage.setItem('@diel:custom-backend', 'http://localhost:8443');
// localStorage.setItem('@diel:custom-backend', 'https://api-qa.dielenergia.com');

export const __API__ = localStorage.getItem('@diel:custom-backend')
  || ((frontHost === 'production') && 'https://api.dielenergia.com')
  || process.env.REACT_APP_API_URL
  || 'http://api.unknown-host'; // force error

let _backendDescriptionToShow: string|null;
switch (__API__) {
  case 'https://api.dielenergia.com': { _backendDescriptionToShow = 'SERVIDOR DE PRODUÇÃO!'; break; }
  case 'https://api-qa.dielenergia.com': { _backendDescriptionToShow = 'QA'; break; }
  case 'https://api-dev-nav.dielenergia.com': { _backendDescriptionToShow = 'DEV-NAV'; break; }
  case 'https://api-demo.dielenergia.com': { _backendDescriptionToShow = 'DEMONSTRAÇÃO'; break; }
  default: { _backendDescriptionToShow = 'SERVIDOR DE TESTES'; break; }
}

if ((frontHost === 'production') && (__API__ === 'https://api.dielenergia.com')) { _backendDescriptionToShow = null; }

export const backendDescriptionToShow = _backendDescriptionToShow;

export const __WS_URL__ = process.env.REACT_APP_WEBSOCKET_URL || `ws${__API__.substring(4)}:8010/wsfront`;

const defaultOptions = {
  baseURL: __API__,
};

const instance = axios.create(defaultOptions);

instance.interceptors.request.use((config) => {
  if (localStorage.getItem('@diel:token')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('@diel:token')}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      localStorage.clear();
    }
    if (
      error
      && error.response
      && error.response.status === 401
      && window.location.pathname !== '/login'
      && window.location.pathname !== '/esqueceu-senha'
      && window.location.pathname !== '/configuracoes/alterar-senha'
    ) {
      window.location.href = '/login';
    } else {
      return Promise.reject(error);
    }
  },
);

export function reqWithCredentials(authHeader: string, route: string, body?: string|{}) {
  const config: AxiosRequestConfig = {
    method: 'post',
    baseURL: __API__,
    url: route,
    data: body,
    headers: {},
  };
  if (authHeader && authHeader.length > 10) config.headers.Authorization = authHeader;
  return axios(config);
}

export function unicodeBase64(str: string) {
  const encoded = encodeURIComponent(str);
  const equivString = encoded.replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(`0x${p1}`, 16)));
  return btoa(equivString);
}

async function dielApiPost(route: string, body?: any) {
  const token = localStorage.getItem('@diel:token');
  const prefsStorage = (localStorage.getItem('@diel:profile') && JSON.parse(localStorage.getItem('@diel:profile')!).prefs) || undefined;
  const formatLanguage = (prefsStorage && JSON.parse(prefsStorage).language) || 'pt';
  const authorization = (token && `Bearer ${token}`);
  apmElastic(route);

  const config: AxiosRequestConfig = {
    method: 'post',
    baseURL: __API__,
    url: route,
    data: body,
    headers: {
      'Accept-Language': formatLanguage || undefined,
    },
  };
  if (authorization && authorization.length > 10) config.headers.Authorization = authorization;
  return axios(config);
}

const api: API_private & API_public & API_uploadService = {
  '/login': (reqParams) => dielApiPost('/login', reqParams).then((r) => r.data),
  '/login/forgot': (reqParams) => dielApiPost('/login/forgot', reqParams).then((r) => r.data),
  '/login/reset': (reqParams) => dielApiPost('/login/reset', reqParams).then((r) => r.data),
  '/ota/:hw_type/:hardware_revision/:current_version/:dev_id': (reqParams) => dielApiPost('/ota/:hw_type/:hardware_revision/:current_version/:dev_id', reqParams).then((r) => r.data),
  '/ota/:hw_type/:hardware_revision/:current_version': (reqParams) => dielApiPost('/ota/:hw_type/:hardware_revision/:current_version', reqParams).then((r) => r.data),
  '/ota': (reqParams) => dielApiPost('/ota', reqParams).then((r) => r.data),
  '/ota-get/:fw_type/:hardware_revision/:wanted_version': (reqParams) => dielApiPost('/ota-get/:fw_type/:hardware_revision/:wanted_version', reqParams).then((r) => r.data),
  '/ota-for-dev/:dev_id': (reqParams) => dielApiPost('/ota-for-dev/:dev_id', reqParams).then((r) => r.data),
  '/ota-for-dev/:dev_id/:hardware_revision/:current_version': (reqParams) => dielApiPost('/ota-for-dev/:dev_id/:hardware_revision/:current_version', reqParams).then((r) => r.data),
  '/ota-check-cert/:devId': (reqParams) => dielApiPost('/ota-check-cert/:devId', reqParams).then((r) => r.data),
  '/unsubscribe/faults/:token': (reqParams) => dielApiPost('/unsubscribe/faults/:token', reqParams).then((r) => r.data),
  '/unsubscribe/faults': (reqParams) => dielApiPost('/unsubscribe/faults', reqParams).then((r) => r.data),
  '/my-profile': (reqParams) => dielApiPost('/my-profile', reqParams).then((r) => r.data),
  '/dam/get-dams-list': (reqParams) => dielApiPost('/dam/get-dams-list', reqParams).then((r) => r.data),
  '/dam/get-dam-info': (reqParams) => dielApiPost('/dam/get-dam-info', reqParams).then((r) => r.data),
  '/dam/set-dam-info': (reqParams) => dielApiPost('/dam/set-dam-info', reqParams).then((r) => r.data),
  '/dam/delete-dam-info': (reqParams) => dielApiPost('/dam/delete-dam-info', reqParams).then((r) => r.data),
  '/dam/set-dam-operation': (reqParams) => dielApiPost('/dam/set-dam-operation', reqParams).then((r) => r.data),
  '/dam/set-dam-illumination': (reqParams) => dielApiPost('/dam/set-dam-illumination', reqParams).then((r) => r.data),
  '/dam/get-dam-illumination-validation': (reqParams) => dielApiPost('/dam/get-dam-illumination-validation', reqParams).then((r) => r.data),
  '/users/list-users': (reqParams) => dielApiPost('/users/list-users', reqParams).then((r) => r.data),
  '/users/get-prefs-overview': (reqParams) => dielApiPost('/users/get-prefs-overview', reqParams).then((r) => r.data),
  '/users/set-prefs-overview': (reqParams) => dielApiPost('/users/set-prefs-overview', reqParams).then((r) => r.data),
  '/users/get-user-info': (reqParams) => dielApiPost('/users/get-user-info', reqParams).then((r) => r.data),
  '/users/invite-new-user': (reqParams) => dielApiPost('/users/invite-new-user', reqParams).then((r) => r.data),
  '/users/edit-user': (reqParams) => dielApiPost('/users/edit-user', reqParams).then((r) => r.data),
  '/users/remove-user': (reqParams) => dielApiPost('/users/remove-user', reqParams).then((r) => r.data),
  '/users/reactivate-user': (reqParams) => dielApiPost('/users/reactivate-user', reqParams).then((r) => r.data),
  '/users/set-notif-unitrep': (reqParams) => dielApiPost('/users/set-notif-unitrep', reqParams).then((r) => r.data),
  '/users/get-notif-unitrep': (reqParams) => dielApiPost('/users/get-notif-unitrep', reqParams).then((r) => r.data),
  '/users/set-notif-faults': (reqParams) => dielApiPost('/users/set-notif-faults', reqParams).then((r) => r.data),
  '/users/get-notif-faults': (reqParams) => dielApiPost('/users/get-notif-faults', reqParams).then((r) => r.data),
  '/request-dev-cert-check': (reqParams) => dielApiPost('/request-dev-cert-check', reqParams).then((r) => r.data),
  '/devs/get-firmware-versions': (reqParams) => dielApiPost('/devs/get-firmware-versions', reqParams).then((r) => r.data),
  '/devs/get-devs-fwinfo': (reqParams) => dielApiPost('/devs/get-devs-fwinfo', reqParams).then((r) => r.data),
  '/devs/get-devs-fwinfo-v2': (reqParams) => dielApiPost('/devs/get-devs-fwinfo-v2', reqParams).then((r) => r.data),
  '/devs/upload-new-firmware-version': (reqParams) => dielApiPost('/devs/upload-new-firmware-version', reqParams).then((r) => r.data),
  '/dac/add-client-unit': (reqParams) => dielApiPost('/dac/add-client-unit', reqParams).then((r) => r.data),
  '/dac/add-new-group': (reqParams) => dielApiPost('/dac/add-new-group', reqParams).then((r) => r.data),
  '/heat-exchanger/get-list': (reqParams) => dielApiPost('/heat-exchanger/get-list', reqParams).then((r) => r.data),
  '/heat-exchanger/get-list-v2': (reqParams) => dielApiPost('/heat-exchanger/get-list-v2', reqParams).then((r) => r.data),
  '/heat-exchanger/delete-info': (reqParams) => dielApiPost('/heat-exchanger/delete-info', reqParams).then((r) => r.data),
  '/heat-exchanger/create': (reqParams) => dielApiPost('/heat-exchanger/create', reqParams).then((r) => r.data),
  '/heat-exchanger/create-v2': (reqParams) => dielApiPost('/heat-exchanger/create-v2', reqParams).then((r) => r.data),
  '/heat-exchanger/set-info': (reqParams) => dielApiPost('/heat-exchanger/set-info', reqParams).then((r) => r.data),
  '/heat-exchanger/set-info-v2': (reqParams) => dielApiPost('/heat-exchanger/set-info-v2', reqParams).then((r) => r.data),
  '/heat-exchanger/get-brands-list': (reqParams) => dielApiPost('/heat-exchanger/get-brands-list', reqParams).then((r) => r.data),
  '/heat-exchanger/get-brands-list-v2': (reqParams) => dielApiPost('/heat-exchanger/get-brands-list-v2', reqParams).then((r) => r.data),
  '/heat-exchanger/get-info': (reqParams) => dielApiPost('/heat-exchanger/get-info', reqParams).then((r) => r.data),
  '/heat-exchanger/get-info-v2': (reqParams) => dielApiPost('/heat-exchanger/get-info-v2', reqParams).then((r) => r.data),
  '/clients/add-new-room': (reqParams) => dielApiPost('/clients/add-new-room', reqParams).then((r) => r.data),
  '/clients/get-units-list': (reqParams) => dielApiPost('/clients/get-units-list', reqParams).then((r) => r.data),
  '/clients/get-units-list-basic': (reqParams) => dielApiPost('/clients/get-units-list-basic', reqParams).then((r) => r.data),
  '/clients/get-unit-info': (reqParams) => dielApiPost('/clients/get-unit-info', reqParams).then((r) => r.data),
  '/clients/edit-unit': (reqParams) => dielApiPost('/clients/edit-unit', reqParams).then((r) => r.data),
  '/clients/remove-unit': (reqParams) => dielApiPost('/clients/remove-unit', reqParams).then((r) => r.data),
  '/clients/get-groups-list': (reqParams) => dielApiPost('/clients/get-groups-list', reqParams).then((r) => r.data),
  '/clients/edit-group': (reqParams) => dielApiPost('/clients/edit-group', reqParams).then((r) => r.data),
  '/clients/remove-group': (reqParams) => dielApiPost('/clients/remove-group', reqParams).then((r) => r.data),
  '/clients/get-roomtypes-list': (reqParams) => dielApiPost('/clients/get-roomtypes-list', reqParams).then((r) => r.data),
  '/clients/add-new-roomtype': (reqParams) => dielApiPost('/clients/add-new-roomtype', reqParams).then((r) => r.data),
  '/clients/edit-roomtype': (reqParams) => dielApiPost('/clients/edit-roomtype', reqParams).then((r) => r.data),
  '/clients/remove-roomtype': (reqParams) => dielApiPost('/clients/remove-roomtype', reqParams).then((r) => r.data),
  '/clients/get-clients-list': (reqParams) => dielApiPost('/clients/get-clients-list', reqParams).then((r) => r.data),
  '/get-integrations-list/water-overview': (reqParams) => dielApiPost('/get-integrations-list/water-overview', reqParams).then((r) => r.data),
  '/get-integrations-list/water-overview-v2': (reqParams) => dielApiPost('/get-integrations-list/water-overview-v2', reqParams).then((r) => r.data),
  '/energy/get-energy-list-overview': (reqParams) => dielApiPost('/energy/get-energy-list-overview', reqParams).then((r) => r.data),
  '/energy/get-energy-list-overview-v2': (reqParams) => dielApiPost('/energy/get-energy-list-overview-v2', reqParams).then((r) => r.data),
  '/environments/get-environment-list': (reqParams) => dielApiPost('/environments/get-environment-list', reqParams).then((r) => r.data),
  '/environments/get-environment-list-overview': (reqParams) => dielApiPost('/environments/get-environment-list-overview', reqParams).then((r) => r.data),
  '/environments/get-environment-list-overview-v2': (reqParams) => dielApiPost('/environments/get-environment-list-overview-v2', reqParams).then((r) => r.data),
  '/environments/delete-environment': (reqParams) => dielApiPost('/environments/delete-environment', reqParams).then((r) => r.data),
  '/environments/edit-environment': (reqParams) => dielApiPost('/environments/edit-environment', reqParams).then((r) => r.data),
  '/clients/get-client-info': (reqParams) => dielApiPost('/clients/get-client-info', reqParams).then((r) => r.data),
  '/clients/add-new-client': (reqParams) => dielApiPost('/clients/add-new-client', reqParams).then((r) => r.data),
  '/clients/edit-client': (reqParams) => dielApiPost('/clients/edit-client', reqParams).then((r) => r.data),
  '/clients/remove-client': (reqParams) => dielApiPost('/clients/remove-client', reqParams).then((r) => r.data),
  '/clients/get-associations-list': (reqParams) => dielApiPost('/clients/get-associations-list', reqParams).then((r) => r.data),
  '/clients/get-association-info': (reqParams) => dielApiPost('/clients/get-association-info', reqParams).then((r) => r.data),
  '/clients/add-new-association': (reqParams) => dielApiPost('/clients/add-new-association', reqParams).then((r) => r.data),
  '/clients/remove-association': (reqParams) => dielApiPost('/clients/remove-association', reqParams).then((r) => r.data),
  '/clients/edit-association': (reqParams) => dielApiPost('/clients/edit-association', reqParams).then((r) => r.data),
  '/clients/get-unit-devs-disp': (reqParams) => dielApiPost('/clients/get-unit-devs-disp', reqParams).then((r) => r.data),
  '/clients/get-client-units-disp': (reqParams) => dielApiPost('/clients/get-client-units-disp', reqParams).then((r) => r.data),
  '/clients/get-unit-devs-disp-v2': (reqParams) => dielApiPost('/clients/get-unit-devs-disp-v2', reqParams).then((r) => r.data),
  '/dac/edit-notification-request': (reqParams) => dielApiPost('/dac/edit-notification-request', reqParams).then((r) => r.data),
  '/dac/add-notification-request': (reqParams) => dielApiPost('/dac/add-notification-request', reqParams).then((r) => r.data),
  '/dac/list-notification-requests': (reqParams) => dielApiPost('/dac/list-notification-requests', reqParams).then((r) => r.data),
  '/dac/get-notification-request': (reqParams) => dielApiPost('/dac/get-notification-request', reqParams).then((r) => r.data),
  '/dac/remove-notification-request': (reqParams) => dielApiPost('/dac/remove-notification-request', reqParams).then((r) => r.data),
  '/dac/notifications-options': (reqParams) => dielApiPost('/dac/notifications-options', reqParams).then((r) => r.data),
  '/energy/add-notification-request': (reqParams) => dielApiPost('/energy/add-notification-request', reqParams).then((r) => r.data),
  '/energy/edit-notification-request': (reqParams) => dielApiPost('/energy/edit-notification-request', reqParams).then((r) => r.data),
  '/get-holidays-list': (reqParams) => dielApiPost('/get-holidays-list', reqParams).then((r) => r.data),
  '/dac/get-cities-list': (reqParams) => dielApiPost('/dac/get-cities-list', reqParams).then((r) => r.data),
  '/dac/search-city-info': (reqParams) => dielApiPost('/dac/search-city-info', reqParams).then((r) => r.data),
  '/dac/add-new-city': (reqParams) => dielApiPost('/dac/add-new-city', reqParams).then((r) => r.data),
  '/dac/get-states-list': (reqParams) => dielApiPost('/dac/get-states-list', reqParams).then((r) => r.data),
  '/dac/remove-city': (reqParams) => dielApiPost('/dac/remove-city', reqParams).then((r) => r.data),
  '/dac/edit-city': (reqParams) => dielApiPost('/dac/edit-city', reqParams).then((r) => r.data),
  '/faults/get-fault-codes': (reqParams) => dielApiPost('/faults/get-fault-codes', reqParams).then((r) => r.data),
  '/dac/get-health-status': (reqParams) => dielApiPost('/dac/get-health-status', reqParams).then((r) => r.data),
  '/dac/save-health-info': (reqParams) => dielApiPost('/dac/save-health-info', reqParams).then((r) => r.data),
  '/dac/save-observation-info': (reqParams) => dielApiPost('/dac/save-observation-info', reqParams).then((r) => r.data),
  '/dac/edit-observation-info': (reqParams) => dielApiPost('/dac/edit-observation-info', reqParams).then((r) => r.data),
  '/dac/detected-fault-confirmation': (reqParams) => dielApiPost('/dac/detected-fault-confirmation', reqParams).then((r) => r.data),
  '/dam/clear-fault-daminop': (reqParams) => dielApiPost('/dam/clear-fault-daminop', reqParams).then((r) => r.data),
  '/dut/get-day-stats': (reqParams) => dielApiPost('/dut/get-day-stats', reqParams).then((r) => r.data),
  '/dut/get-day-charts-data': (reqParams) => dielApiPost('/dut/get-day-charts-data', reqParams).then((r) => r.data),
  '/dut/get-day-charts-data-commonX': (reqParams) => dielApiPost('/dut/get-day-charts-data-commonX', reqParams).then((r) => r.data),
  '/dac/get-day-charts-data': (reqParams) => dielApiPost('/dac/get-day-charts-data', reqParams).then((r) => r.data),
  '/dac/get-recent-history': (reqParams) => dielApiPost('/dac/get-recent-history', reqParams).then((r) => r.data),
  '/dac/get-recent-history-v2': (reqParams) => dielApiPost('/dac/get-recent-history-v2', reqParams).then((r) => r.data),
  '/dac/get-images-list': (reqParams) => dielApiPost('/dac/get-images-list', reqParams).then((r) => r.data),
  '/dac/delete-image': (reqParams) => dielApiPost('/dac/delete-image', reqParams).then((r) => r.data),
  '/dut/get-images-list': (reqParams) => dielApiPost('/dut/get-images-list', reqParams).then((r) => r.data),
  '/dut/delete-image': (reqParams) => dielApiPost('/dut/delete-image', reqParams).then((r) => r.data),
  '/dam/get-images-list': (reqParams) => dielApiPost('/dam/get-images-list', reqParams).then((r) => r.data),
  '/dam/delete-image': (reqParams) => dielApiPost('/dam/delete-image', reqParams).then((r) => r.data),
  '/dri/get-images-list': (reqParams) => dielApiPost('/dri/get-images-list', reqParams).then((r) => r.data),
  '/dri/delete-image': (reqParams) => dielApiPost('/dri/delete-image', reqParams).then((r) => r.data),
  '/dma/get-images-list': (reqParams) => dielApiPost('/dma/get-images-list', reqParams).then((r) => r.data),
  '/dma/delete-image': (reqParams) => dielApiPost('/dma/delete-image', reqParams).then((r) => r.data),
  '/dac/get-dacs-list': (reqParams) => dielApiPost('/dac/get-dacs-list', reqParams).then((r) => r.data),
  '/clients/get-units-with-energy-device': (reqParams) => dielApiPost('/clients/get-units-with-energy-device', reqParams).then((r) => r.data),
  '/get-dacs-faults': (reqParams) => dielApiPost('/get-dacs-faults', reqParams).then((r) => r.data),
  '/get-dams-faults': (reqParams) => dielApiPost('/get-dams-faults', reqParams).then((r) => r.data),
  '/dac/get-dac-usage': (reqParams) => dielApiPost('/dac/get-dac-usage', reqParams).then((r) => r.data),
  '/get-dev-full-info': (reqParams) => dielApiPost('/get-dev-full-info', reqParams).then((r) => r.data),
  '/get-devs-full-info': (reqParams) => dielApiPost('/get-devs-full-info', reqParams).then((r) => r.data),
  '/dac/get-dac-info': (reqParams) => dielApiPost('/dac/get-dac-info', reqParams).then((r) => r.data),
  '/dac/set-dac-info': (reqParams) => dielApiPost('/dac/set-dac-info', reqParams).then((r) => r.data),
  '/dev/dev-info-combo-options': (reqParams) => dielApiPost('/dev/dev-info-combo-options', reqParams).then((r) => r.data),
  '/dac/delete-dac-info': (reqParams) => dielApiPost('/dac/delete-dac-info', reqParams).then((r) => r.data),
  '/dut/get-duts-list': (reqParams) => dielApiPost('/dut/get-duts-list', reqParams).then((r) => r.data),
  '/dut/get-dut-info': (reqParams) => dielApiPost('/dut/get-dut-info', reqParams).then((r) => r.data),
  '/dut/delete-dut-info': (reqParams) => dielApiPost('/dut/delete-dut-info', reqParams).then((r) => r.data),
  '/config/add-pressure-sensor': (reqParams) => dielApiPost('/config/add-pressure-sensor', reqParams).then((r) => r.data),
  '/config/delete-pressure-sensor': (reqParams) => dielApiPost('/config/delete-pressure-sensor', reqParams).then((r) => r.data),
  '/config/edit-pressure-sensor': (reqParams) => dielApiPost('/config/edit-pressure-sensor', reqParams).then((r) => r.data),
  '/config/get-pressure-sensors': (reqParams) => dielApiPost('/config/get-pressure-sensors', reqParams).then((r) => r.data),
  '/config/add-manufact-devs': (reqParams) => dielApiPost('/config/add-manufact-devs', reqParams).then((r) => r.data),
  '/config/delete-manufact-devs': (reqParams) => dielApiPost('/config/delete-manufact-devs', reqParams).then((r) => r.data),
  '/config/get-manufact-devs': (reqParams) => dielApiPost('/config/get-manufact-devs', reqParams).then((r) => r.data),
  '/get-unit-energy-consumption': (reqParams) => dielApiPost('/get-unit-energy-consumption', reqParams).then((r) => r.data),
  '/get-unit-energy-consumption-commonX': (reqParams) => dielApiPost('/get-unit-energy-consumption-commonX', reqParams).then((r) => r.data),
  '/get-unit-energy-consumption-byDay': (reqParams) => dielApiPost('/get-unit-energy-consumption-byDay', reqParams).then((r) => r.data),
  '/analise-integrada-export': (reqParams) => dielApiPost('/analise-integrada-export', reqParams).then((r) => r.data),
  '/dac/export-unit-report': (reqParams) => dielApiPost('/dac/export-unit-report', reqParams).then((r) => r.data),
  '/dac/export-preventive-report': (reqParams) => dielApiPost('/dac/export-preventive-report', reqParams).then((r) => r.data),
  '/get-private-unit-report-file': (reqParams) => dielApiPost('/get-private-unit-report-file', reqParams).then((r) => r.data),
  '/dac/get-energy-consumption-month': (reqParams) => dielApiPost('/dac/get-energy-consumption-month', reqParams).then((r) => r.data),
  '/dac/get-usage-per-month': (reqParams) => dielApiPost('/dac/get-usage-per-month', reqParams).then((r) => r.data),
  '/dac/get-usage-per-day': (reqParams) => dielApiPost('/dac/get-usage-per-day', reqParams).then((r) => r.data),
  '/dac/get-energy-consumption': (reqParams) => dielApiPost('/dac/get-energy-consumption', reqParams).then((r) => r.data),
  '/dac/get-health-hist': (reqParams) => dielApiPost('/dac/get-health-hist', reqParams).then((r) => r.data),
  '/dac/get-observation': (reqParams) => dielApiPost('/dac/get-observation', reqParams).then((r) => r.data),
  '/sims-v2': (reqParams) => dielApiPost('/sims-v2', reqParams).then((r) => r.data),
  '/sims/set-sim-info': (reqParams) => dielApiPost('/sims/set-sim-info', reqParams).then((r) => r.data),
  '/dac/upload-image': (reqParams) => dielApiPost('/dac/upload-image', reqParams).then((r) => r.data),
  '/dut/upload-image': (reqParams) => dielApiPost('/dut/upload-image', reqParams).then((r) => r.data),
  '/dam/upload-image': (reqParams) => dielApiPost('/dam/upload-image', reqParams).then((r) => r.data),
  '/dri/upload-image': (reqParams) => dielApiPost('/dri/upload-image', reqParams).then((r) => r.data),
  '/dma/upload-image': (reqParams) => dielApiPost('/dma/upload-image', reqParams).then((r) => r.data),
  '/devtools/run-command': (reqParams) => dielApiPost('/devtools/run-command', reqParams).then((r) => r.data),
  '/login/impersonate': (reqParams) => dielApiPost('/login/impersonate', reqParams).then((r) => r.data),
  '/login/craft-token': (reqParams) => dielApiPost('/login/craft-token', reqParams).then((r) => r.data),
  '/users/set-profiles': (reqParams) => dielApiPost('/users/set-profiles', reqParams).then((r) => r.data),
  '/users/send-tg-number-check': (reqParams) => dielApiPost('/users/send-tg-number-check', reqParams).then((r) => r.data),
  '/users/check-tg-number-code': (reqParams) => dielApiPost('/users/check-tg-number-code', reqParams).then((r) => r.data),
  '/log/query-dev-log': (reqParams) => dielApiPost('/log/query-dev-log', reqParams).then((r) => r.data),
  '/send-dut-aut-command': (reqParams) => dielApiPost('/send-dut-aut-command', reqParams).then((r) => r.data),
  '/resend-dut-ir-codes': (reqParams) => dielApiPost('/resend-dut-ir-codes', reqParams).then((r) => r.data),
  '/define-ircode-action': (reqParams) => dielApiPost('/define-ircode-action', reqParams).then((r) => r.data),
  '/delete-dut-ircode': (reqParams) => dielApiPost('/delete-dut-ircode', reqParams).then((r) => r.data),
  '/get-dut-ircodes-list': (reqParams) => dielApiPost('/get-dut-ircodes-list', reqParams).then((r) => r.data),
  '/get-duts-ircodes-list': (reqParams) => dielApiPost('/get-duts-ircodes-list', reqParams).then((r) => r.data),
  '/add-client-units-batch': (reqParams) => dielApiPost('/add-client-units-batch', reqParams).then((r) => r.data),
  '/check-client-units-batch': (reqParams) => dielApiPost('/check-client-units-batch', reqParams).then((r) => r.data),
  '/add-client-unified-batch': (reqParams) => dielApiPost('/add-client-unified-batch', reqParams).then((r) => r.data),
  '/check-client-unified-batch': (reqParams) => dielApiPost('/check-client-unified-batch', reqParams).then((r) => r.data),
  '/add-client-dacs-batch': (reqParams) => dielApiPost('/add-client-dacs-batch', reqParams).then((r) => r.data),
  '/check-client-dacs-batch': (reqParams) => dielApiPost('/check-client-dacs-batch', reqParams).then((r) => r.data),
  '/add-client-duts-batch': (reqParams) => dielApiPost('/add-client-duts-batch', reqParams).then((r) => r.data),
  '/add-client-dmas-batch': (reqParams) => dielApiPost('/add-client-dmas-batch', reqParams).then((r) => r.data),
  '/check-client-duts-batch': (reqParams) => dielApiPost('/check-client-duts-batch', reqParams).then((r) => r.data),
  '/check-client-dmas-batch': (reqParams) => dielApiPost('/check-client-dmas-batch', reqParams).then((r) => r.data),
  '/add-client-roomtypes-batch': (reqParams) => dielApiPost('/add-client-roomtypes-batch', reqParams).then((r) => r.data),
  '/check-client-roomtypes-batch': (reqParams) => dielApiPost('/check-client-roomtypes-batch', reqParams).then((r) => r.data),
  '/add-client-dams-batch': (reqParams) => dielApiPost('/add-client-dams-batch', reqParams).then((r) => r.data),
  '/check-client-dams-batch': (reqParams) => dielApiPost('/check-client-dams-batch', reqParams).then((r) => r.data),
  '/check-client-dris-batch': (reqParams) => dielApiPost('/check-client-dris-batch', reqParams).then((r) => r.data),
  '/add-client-dris-batch': (reqParams) => dielApiPost('/add-client-dris-batch', reqParams).then((r) => r.data),
  '/add-client-supervisors-batch': (reqParams) => dielApiPost('/add-client-supervisors-batch', reqParams).then((r) => r.data),
  '/check-client-supervisors-batch': (reqParams) => dielApiPost('/check-client-supervisors-batch', reqParams).then((r) => r.data),
  '/batch-input-columns': (reqParams) => dielApiPost('/batch-input-columns', reqParams).then((r) => r.data),
  '/diel-site-our-numbers': (reqParams) => dielApiPost('/diel-site-our-numbers', reqParams).then((r) => r.data),
  '/login/craft-redirect-to-dash-token': (reqParams) => dielApiPost('/login/craft-redirect-to-dash-token', reqParams).then((r) => r.data),
  '/calculate-ecomode-savings': (reqParams) => dielApiPost('/calculate-ecomode-savings', reqParams).then((r) => r.data),
  '/unit-ecomode-savings': (reqParams) => dielApiPost('/unit-ecomode-savings', reqParams).then((r) => r.data),
  '/unit-automation-savings': (reqParams) => dielApiPost('/unit-automation-savings', reqParams).then((r) => r.data),
  '/ness/get-meters-list': (reqParams) => dielApiPost('/ness/get-meters-list', reqParams).then((r) => r.data),
  '/ness/get-meter-history': (reqParams) => dielApiPost('/ness/get-meter-history', reqParams).then((r) => r.data),
  '/get-autom-day-charts-data': (reqParams) => dielApiPost('/get-autom-day-charts-data', reqParams).then((r) => r.data),
  '/dut/set-dut-aut-operation': (reqParams) => dielApiPost('/dut/set-dut-aut-operation', reqParams).then((r) => r.data),
  '/dal/set-dal-operation': (reqParams) => dielApiPost('/dal/set-dal-operation', reqParams).then((r) => r.data),
  '/dut/set-dut-info': (reqParams) => dielApiPost('/dut/set-dut-info', reqParams).then((r) => r.data),
  '/dma/set-dma-info': (reqParams) => dielApiPost('/dma/set-dma-info', reqParams).then((r) => r.data),
  '/dma/get-day-usage': (reqParams) => dielApiPost('/dma/get-day-usage', reqParams).then((r) => r.data),
  '/dma/get-usage-history': (reqParams) => dielApiPost('/dma/get-usage-history', reqParams).then((r) => r.data),
  '/dma/get-last-reading-date': (reqParams) => dielApiPost('/dma/get-last-reading-date', reqParams).then((r) => r.data),
  '/dma/set-sampling-period': (reqParams) => dielApiPost('/dma/set-sampling-period', reqParams).then((r) => r.data),
  '/dma/get-dma-info': (reqParams) => dielApiPost('/dma/get-dma-info', reqParams).then((r) => r.data),
  '/dma/get-disassociated-dmas': (reqParams) => dielApiPost('/dma/get-disassociated-dmas', reqParams).then((r) => r.data),
  '/dma/get-dma-mean-history': (reqParams) => dielApiPost('/dma/get-dma-mean-history', reqParams).then((r) => r.data),
  '/dac/get-day-consumption': (reqParams) => dielApiPost('/dac/get-day-consumption', reqParams).then((r) => r.data),
  '/clients/get-units-and-meters': (reqParams) => dielApiPost('/clients/get-units-and-meters', reqParams).then((r) => r.data),
  '/dac/get-fr-list-updates': (reqParams) => dielApiPost('/dac/get-fr-list-updates', reqParams).then((r) => r.data),
  '/devtools/export-dacs-info': (reqParams) => dielApiPost('/devtools/export-dacs-info', reqParams).then((r) => r.data),
  '/dac/get-dacs-list-by-unit': (reqParams) => dielApiPost('/dac/get-dacs-list-by-unit', reqParams).then((r) => r.data),
  '/get-units-list-page': (reqParams) => dielApiPost('/get-units-list-page', reqParams).then((r) => r.data),
  '/get-environments-list-page': (reqParams) => dielApiPost('/get-environments-list-page', reqParams).then((r) => r.data),
  '/get-autom-devs-list': (reqParams) => dielApiPost('/get-autom-devs-list', reqParams).then((r) => r.data),
  '/dacs-with-automation-enabled': (reqParams) => dielApiPost('/dacs-with-automation-enabled', reqParams).then((r) => r.data),
  '/delete-dac-health-hist': (reqParams) => dielApiPost('/delete-dac-health-hist', reqParams).then((r) => r.data),
  '/delete-dac-observation': (reqParams) => dielApiPost('/delete-dac-observation', reqParams).then((r) => r.data),
  '/disable-dut-aut-commands': (reqParams) => dielApiPost('/disable-dut-aut-commands', reqParams).then((r) => r.data),
  '/get-machines-automation-info': (reqParams) => dielApiPost('/get-machines-automation-info', reqParams).then((r) => r.data),
  '/ignore-fault-check': (reqParams) => dielApiPost('/ignore-fault-check', reqParams).then((r) => r.data),
  '/units-automation-savings': (reqParams) => dielApiPost('/units-automation-savings', reqParams).then((r) => r.data),
  '/automation-overview-card': (reqParams) => dielApiPost('/automation-overview-card', reqParams).then((r) => r.data),
  '/get-units-energy-consumption-byDay': (reqParams) => dielApiPost('/get-units-energy-consumption-byDay', reqParams).then((r) => r.data),
  '/resend-dut-aut-config': (reqParams) => dielApiPost('/resend-dut-aut-config', reqParams).then((r) => r.data),
  '/health-overview-card': (reqParams) => dielApiPost('/health-overview-card', reqParams).then((r) => r.data),
  '/energy-efficiency-overview-card': (reqParams) => dielApiPost('/energy-efficiency-overview-card', reqParams).then((r) => r.data),
  '/dri/set-dri-info': (reqParams) => dielApiPost('/dri/set-dri-info', reqParams).then((r) => r.data),
  '/dri/get-day-charts-data-common': (reqParams) => dielApiPost('/dri/get-day-charts-data-common', reqParams).then((r) => r.data),
  '/dri/get-dris-list': (reqParams) => dielApiPost('/dri/get-dris-list', reqParams).then((r) => r.data),
  '/dri/add-dri-sched': (reqParams) => dielApiPost('/dri/add-dri-sched', reqParams).then((r) => r.data),
  '/dri/update-dri-sched': (reqParams) => dielApiPost('/dri/update-dri-sched', reqParams).then((r) => r.data),
  '/dri/delete-dri-sched': (reqParams) => dielApiPost('/dri/delete-dri-sched', reqParams).then((r) => r.data),
  '/dri/get-dri-scheds': (reqParams) => dielApiPost('/dri/get-dri-scheds', reqParams).then((r) => r.data),
  '/dri/send-dri-command': (reqParams) => dielApiPost('/dri/send-dri-command', reqParams).then((r) => r.data),
  '/dri/update-time-send-config': (reqParams) => dielApiPost('/dri/update-time-send-config', reqParams).then((r) => r.data),
  '/dri/update-dri-vav': (reqParams) => dielApiPost('/dri/update-dri-vav', reqParams).then((r) => r.data),
  '/dri/get-dri-vav-info': (reqParams) => dielApiPost('/dri/get-dri-vav-info', reqParams).then((r) => r.data),
  '/dri/get-dri-vavs-list': (reqParams) => dielApiPost('/dri/get-dri-vavs-list', reqParams).then((r) => r.data),
  '/dri/update-dri-fancoil': (reqParams) => dielApiPost('/dri/update-dri-fancoil', reqParams).then((r) => r.data),
  '/dri/get-dri-fancoil-info': (reqParams) => dielApiPost('/dri/get-dri-fancoil-info', reqParams).then((r) => r.data),
  '/export-dacs-faults': (reqParams) => dielApiPost('/export-dacs-faults', reqParams).then((r) => r.data),
  '/request-dut-aut-config': (reqParams) => dielApiPost('/request-dut-aut-config', reqParams).then((r) => r.data),
  '/automation-type-list': (reqParams) => dielApiPost('/automation-type-list', reqParams).then((r) => r.data),
  '/online-devices-overview': (reqParams) => dielApiPost('/online-devices-overview', reqParams).then((r) => r.data),
  '/send-dri-command-reboot': (reqParams) => dielApiPost('/send-dri-command-reboot', reqParams).then((r) => r.data),
  '/dev/command-ota': (reqParams) => dielApiPost('/dev/command-ota', reqParams).then((r) => r.data),
  '/dev/request-firmware-info': (reqParams) => dielApiPost('/dev/request-firmware-info', reqParams).then((r) => r.data),
  '/get-devs-list': (reqParams) => dielApiPost('/get-devs-list', reqParams).then((r) => r.data),
  '/oxyn-get-machine-last-values': (reqParams) => dielApiPost('/oxyn-get-machine-last-values', reqParams).then((r) => r.data),
  '/export-client-dacs-batch-input': (reqParams) => dielApiPost('/export-client-dacs-batch-input', reqParams).then((r) => r.data),
  '/login/craft-static-user-token': (reqParams) => dielApiPost('/login/craft-static-user-token', reqParams).then((r) => r.data),
  '/ness-get-overview-data/json': (reqParams) => dielApiPost('/ness-get-overview-data/json', reqParams).then((r) => r.data),
  '/oxyn-get-machine-charts-v2': (reqParams) => dielApiPost('/oxyn-get-machine-charts-v2', reqParams).then((r) => r.data),
  '/get-integrations-list': (reqParams) => dielApiPost('/get-integrations-list', reqParams).then((r) => r.data),
  '/get-integrations-list/water': (reqParams) => dielApiPost('/get-integrations-list/water', reqParams).then((r) => r.data),
  '/get-integrations-list/energy': (reqParams) => dielApiPost('/get-integrations-list/energy', reqParams).then((r) => r.data),
  '/get-ness-dashboard-url-for-unit': (reqParams) => dielApiPost('/get-ness-dashboard-url-for-unit', reqParams).then((r) => r.data),
  '/get-integration-info': (reqParams) => dielApiPost('/get-integration-info', reqParams).then((r) => r.data),
  '/sims/get-unit-sims': (reqParams) => dielApiPost('/sims/get-unit-sims', reqParams).then((r) => r.data),
  '/sims/delete-sim': (reqParams) => dielApiPost('/sims/delete-sim', reqParams).then((r) => r.data),
  '/save-integration-info': (reqParams) => dielApiPost('/save-integration-info', reqParams).then((r) => r.data),
  '/upload-dri-varscfg': (reqParams) => dielApiPost('/upload-dri-varscfg', reqParams).then((r) => r.data),
  '/vt/set-vt-info': (reqParams) => dielApiPost('/vt/set-vt-info', reqParams).then((r) => r.data),
  '/vt/update-vt-info': (reqParams) => dielApiPost('/vt/update-vt-info', reqParams).then((r) => r.data),
  '/vt/reschedule-vt': (reqParams) => dielApiPost('/vt/reschedule-vt', reqParams).then((r) => r.data),
  '/vt/get-vt-info': (reqParams) => dielApiPost('/vt/get-vt-info', reqParams).then((r) => r.data),
  '/vt/list-vt-byStatus': (reqParams) => dielApiPost('/vt/list-vt-byStatus', reqParams).then((r) => r.data),
  '/vt/list-vt-caracteristicas': (reqParams) => dielApiPost('/vt/list-vt-caracteristicas', reqParams).then((r) => r.data),
  '/vt/approve-vt': (reqParams) => dielApiPost('/vt/approve-vt', reqParams).then((r) => r.data),
  '/vt/delete-vt': (reqParams) => dielApiPost('/vt/delete-vt', reqParams).then((r) => r.data),
  '/vt/technician-vt': (reqParams) => dielApiPost('/vt/technician-vt', reqParams).then((r) => r.data),
  '/devtools/export-duts-info': (reqParams) => dielApiPost('/devtools/export-duts-info', reqParams).then((r) => r.data),
  '/devtools/export-dams-info': (reqParams) => dielApiPost('/devtools/export-dams-info', reqParams).then((r) => r.data),
  '/write-dut-command-by-ircode': (reqParams) => dielApiPost('/write-dut-command-by-ircode', reqParams).then((r) => r.data),
  '/get-dut-ircodes-by-model': (reqParams) => dielApiPost('/get-dut-ircodes-by-model', reqParams).then((r) => r.data),
  '/devtools/broker-servers-status': (reqParams) => dielApiPost('/devtools/broker-servers-status', reqParams).then((r) => r.data),
  '/clients/get-rooms-list': (reqParams) => dielApiPost('/clients/get-rooms-list', reqParams).then((r) => r.data),
  '/clients/edit-room': (reqParams) => dielApiPost('/clients/edit-room', reqParams).then((r) => r.data),
  '/clients/remove-room': (reqParams) => dielApiPost('/clients/remove-room', reqParams).then((r) => r.data),
  '/coolautomation/get-unit-charts': (reqParams) => dielApiPost('/coolautomation/get-unit-charts', reqParams).then((r) => r.data),
  '/coolautomation/get-units-and-systems': (reqParams) => dielApiPost('/coolautomation/get-units-and-systems', reqParams).then((r) => r.data),
  '/coolautomation/associate-device-to-diel-unit': (reqParams) => dielApiPost('/coolautomation/associate-device-to-diel-unit', reqParams).then((r) => r.data),
  '/laager/get-meters-list': (reqParams) => dielApiPost('/laager/get-meters-list', reqParams).then((r) => r.data),
  '/laager/associate-meter-to-diel-unit': (reqParams) => dielApiPost('/laager/associate-meter-to-diel-unit', reqParams).then((r) => r.data),
  '/laager/get-consumo': (reqParams) => dielApiPost('/laager/get-consumo', reqParams).then((r) => r.data),
  '/laager/get-informations': (reqParams) => dielApiPost('/laager/get-informations', reqParams).then((r) => r.data),
  '/laager/get-reading-history': (reqParams) => dielApiPost('/laager/get-reading-history', reqParams).then((r) => r.data),
  '/laager/get-usage-history': (reqParams) => dielApiPost('/laager/get-usage-history', reqParams).then((r) => r.data),
  '/laager/get-daily-usage-history': (reqParams) => dielApiPost('/laager/get-daily-usage-history', reqParams).then((r) => r.data),
  '/laager/get-meter-mean-history': (reqParams) => dielApiPost('/laager/get-meter-mean-history', reqParams).then((r) => r.data),
  '/laager/get-images-list': (reqParams) => dielApiPost('/laager/get-images-list', reqParams).then((r) => r.data),
  '/laager/delete-image': (reqParams) => dielApiPost('/laager/delete-image', reqParams).then((r) => r.data),
  '/laager/upload-image': (reqParams) => dielApiPost('/laager/upload-image', reqParams).then((r) => r.data),
  '/dam/get-programming-v3': (reqParams) => dielApiPost('/dam/get-programming-v3', reqParams).then((r) => r.data),
  '/dam/set-programming-v3': (reqParams) => dielApiPost('/dam/set-programming-v3', reqParams).then((r) => r.data),
  '/dam/update-programming': (reqParams) => dielApiPost('/dam/update-programming', reqParams).then((r) => r.data),
  '/dam/delete-all-schedules': (reqParams) => dielApiPost('/dam/delete-all-schedules', reqParams).then((r) => r.data),
  '/dam/delete-all-exceptions': (reqParams) => dielApiPost('/dam/delete-all-exceptions', reqParams).then((r) => r.data),
  '/set-full-sched-batch-v2': (reqParams) => dielApiPost('/set-full-sched-batch-v2', reqParams).then((r) => r.data),
  '/dam/get-sched-list-v2': (reqParams) => dielApiPost('/dam/get-sched-list-v2', reqParams).then((r) => r.data),
  '/dut/get-programming-v3': (reqParams) => dielApiPost('/dut/get-programming-v3', reqParams).then((r) => r.data),
  '/dut/set-programming-v3': (reqParams) => dielApiPost('/dut/set-programming-v3', reqParams).then((r) => r.data),
  '/dut/update-programming': (reqParams) => dielApiPost('/dut/update-programming', reqParams).then((r) => r.data),
  '/clients/set-room-progs-v2': (reqParams) => dielApiPost('/clients/set-room-progs-v2', reqParams).then((r) => r.data),
  '/clients/get-room-info-v2': (reqParams) => dielApiPost('/clients/get-room-info-v2', reqParams).then((r) => r.data),
  '/weather_data': (reqParams) => dielApiPost('/weather_data', reqParams).then((r) => r.data),
  '/clients/set-unit-supervisors': (reqParams) => dielApiPost('/clients/set-unit-supervisors', reqParams).then((r) => r.data),
  '/clients/get-unit-supervisors': (reqParams) => dielApiPost('/clients/get-unit-supervisors', reqParams).then((r) => r.data),
  '/clients/get-supervisor-units': (reqParams) => dielApiPost('/clients/get-supervisor-units', reqParams).then((r) => r.data),
  '/clients/clear-unit-supervisors': (reqParams) => dielApiPost('/clients/clear-unit-supervisors', reqParams).then((r) => r.data),
  '/clients/add-client-class': (reqParams) => dielApiPost('/clients/add-client-class', reqParams).then((r) => r.data),
  '/clients/get-client-classes': (reqParams) => dielApiPost('/clients/get-client-classes', reqParams).then((r) => r.data),
  '/clients/edit-client-class': (reqParams) => dielApiPost('/clients/edit-client-class', reqParams).then((r) => r.data),
  '/clients/remove-client-class': (reqParams) => dielApiPost('/clients/remove-client-class', reqParams).then((r) => r.data),
  '/clients/set-class-units': (reqParams) => dielApiPost('/clients/set-class-units', reqParams).then((r) => r.data),
  '/clients/get-class-units': (reqParams) => dielApiPost('/clients/get-class-units', reqParams).then((r) => r.data),
  '/clients/get-classes-list': (reqParams) => dielApiPost('/clients/get-classes-list', reqParams).then((r) => r.data),
  '/check-client-assets-batch': (reqParams) => dielApiPost('/check-client-assets-batch', reqParams).then((r) => r.data),
  '/add-client-assets-batch': (reqParams) => dielApiPost('/add-client-assets-batch', reqParams).then((r) => r.data),
  '/export-client-assets-batch-input': (reqParams) => dielApiPost('/export-client-assets-batch-input', reqParams).then((r) => r.data),
  '/export-client-unified-batch-input': (reqParams) => dielApiPost('/export-client-unified-batch-input', reqParams).then((r) => r.data),
  '/clients/add-new-asset': (reqParams) => dielApiPost('/clients/add-new-asset', reqParams).then((r) => r.data),
  '/clients/edit-asset': (reqParams) => dielApiPost('/clients/edit-asset', reqParams).then((r) => r.data),
  '/clients/get-assets-list': (reqParams) => dielApiPost('/clients/get-assets-list', reqParams).then((r) => r.data),
  '/clients/get-asset-info': (reqParams) => dielApiPost('/clients/get-asset-info', reqParams).then((r) => r.data),
  '/clients/remove-asset': (reqParams) => dielApiPost('/clients/remove-asset', reqParams).then((r) => r.data),
  '/vt/pull': (reqParams) => dielApiPost('/vt/pull', reqParams).then((r) => r.data),
  '/vt/push': (reqParams) => dielApiPost('/vt/push', reqParams).then((r) => r.data),
  '/vt/upload-imgs': (reqParams) => dielApiPost('/vt/upload-imgs', reqParams).then((r) => r.data),
  '/asset/upload-image': (reqParams) => dielApiPost('/asset/upload-image', reqParams).then((r) => r.data),
  '/asset/get-images-list': (reqParams) => dielApiPost('/asset/get-images-list', reqParams).then((r) => r.data),
  '/asset/delete-image': (reqParams) => dielApiPost('/asset/delete-image', reqParams).then((r) => r.data),
  '/devgroups/upload-image': (reqParams) => dielApiPost('/devgroups/upload-image', reqParams).then((r) => r.data),
  '/devgroups/get-images-list': (reqParams) => dielApiPost('/devgroups/get-images-list', reqParams).then((r) => r.data),
  '/devgroups/delete-image': (reqParams) => dielApiPost('/devgroups/delete-image', reqParams).then((r) => r.data),
  '/dal/upload-image': (reqParams) => dielApiPost('/dal/upload-image', reqParams).then((r) => r.data),
  '/dal/get-images-list': (reqParams) => dielApiPost('/dal/get-images-list', reqParams).then((r) => r.data),
  '/dal/delete-image': (reqParams) => dielApiPost('/dal/delete-image', reqParams).then((r) => r.data),
  '/illumination/upload-image': (reqParams) => dielApiPost('/illumination/upload-image', reqParams).then((r) => r.data),
  '/illumination/get-images-list': (reqParams) => dielApiPost('/illumination/get-images-list', reqParams).then((r) => r.data),
  '/illumination/delete-image': (reqParams) => dielApiPost('/illumination/delete-image', reqParams).then((r) => r.data),
  '/dmt/upload-image': (reqParams) => dielApiPost('/dmt/upload-image', reqParams).then((r) => r.data),
  '/dmt/get-images-list': (reqParams) => dielApiPost('/dmt/get-images-list', reqParams).then((r) => r.data),
  '/dmt/delete-image': (reqParams) => dielApiPost('/dmt/delete-image', reqParams).then((r) => r.data),
  '/nobreak/upload-image': (reqParams) => dielApiPost('/nobreak/upload-image', reqParams).then((r) => r.data),
  '/nobreak/get-images-list': (reqParams) => dielApiPost('/nobreak/get-images-list', reqParams).then((r) => r.data),
  '/nobreak/delete-image': (reqParams) => dielApiPost('/nobreak/delete-image', reqParams).then((r) => r.data),
  '/unit/upload-sketch': (reqParams) => dielApiPost('/unit/upload-sketch', reqParams).then((r) => r.data),
  '/sims/upload-sim-photo': (reqParams) => dielApiPost('/sims/upload-sim-photo', reqParams).then((r) => r.data),
  '/sims/delete-sim-photo': (reqParams) => dielApiPost('/sims/upload-sim-photo', reqParams).then((r) => r.data),
  '/unit/get-sketches-list': (reqParams) => dielApiPost('/unit/get-sketches-list', reqParams).then((r) => r.data),
  '/unit/edit-sketch': (reqParams) => dielApiPost('/unit/get-sketches-list', reqParams).then((r) => r.data),
  '/unit/delete-sketch': (reqParams) => dielApiPost('/unit/delete-sketch', reqParams).then((r) => r.data),
  '/devs/get-firmware-versions-v2': (reqParams) => dielApiPost('/devs/get-firmware-versions-v2', reqParams).then((r) => r.data),
  '/get-firmware-file/:fwStage/:fwFamily/:fileName': (reqParams) => dielApiPost('/get-firmware-file/:fwStage/:fwFamily/:fileName', reqParams).then((r) => r.data),
  '/coolautomation/get-system-units': (reqParams) => dielApiPost('/coolautomation/get-system-units', reqParams).then((r) => r.data),
  '/coolautomation/get-device-units': (reqParams) => dielApiPost('/coolautomation/get-device-units', reqParams).then((r) => r.data),
  '/coolautomation/get-unit-last-telemetry': (reqParams) => dielApiPost('/coolautomation/get-unit-last-telemetry', reqParams).then((r) => r.data),
  '/coolautomation/control-unit-operation': (reqParams) => dielApiPost('/coolautomation/control-unit-operation', reqParams).then((r) => r.data),
  '/coolautomation/get-system-programming': (reqParams) => dielApiPost('/coolautomation/get-system-programming', reqParams).then((r) => r.data),
  '/coolautomation/get-unit-programming': (reqParams) => dielApiPost('/coolautomation/get-unit-programming', reqParams).then((r) => r.data),
  '/get-weather-stations-near-unit': (reqParams) => dielApiPost('/get-weather-stations-near-unit', reqParams).then((r) => r.data),
  '/get-weather-data-near-unit': (reqParams) => dielApiPost('/get-weather-data-near-unit', reqParams).then((r) => r.data),
  '/get-weather-data-near-unit-commonX': (reqParams) => dielApiPost('/get-weather-data-near-unit-commonX', reqParams).then((r) => r.data),
  '/get-weather-data-near-unit-commonX-v2': (reqParams) => dielApiPost('/get-weather-data-near-unit-commonX-v2', reqParams).then((r) => r.data),
  '/get-integration-dri-info': (reqParams) => dielApiPost('/get-integration-dri-info', reqParams).then((r) => r.data),
  '/get-integration-oxyn-info': (reqParams) => dielApiPost('/get-integration-oxyn-info', reqParams).then((r) => r.data),
  '/get-integration-coolautomation-info': (reqParams) => dielApiPost('/get-integration-coolautomation-info', reqParams).then((r) => r.data),
  '/coolautomation/add-unit-schedule': (reqParams) => dielApiPost('/coolautomation/add-unit-schedule', reqParams).then((r) => r.data),
  '/coolautomation/alter-unit-schedule': (reqParams) => dielApiPost('/coolautomation/alter-unit-schedule', reqParams).then((r) => r.data),
  '/coolautomation/delete-unit-schedule': (reqParams) => dielApiPost('/coolautomation/delete-unit-schedule', reqParams).then((r) => r.data),
  '/coolautomation/get-unit-history': (reqParams) => dielApiPost('/coolautomation/get-unit-history', reqParams).then((r) => r.data),
  '/dac/get-charts-data-common': (reqParams) => dielApiPost('/dac/get-charts-data-common', reqParams).then((r) => r.data),
  '/devtools/processDacDay': (reqParams) => dielApiPost('/devtools/processDacDay', reqParams).then((r) => r.data),
  '/devtools/processDutDay': (reqParams) => dielApiPost('/devtools/processDutDay', reqParams).then((r) => r.data),
  '/devtools/processDamDay': (reqParams) => dielApiPost('/devtools/processDamDay', reqParams).then((r) => r.data),
  '/get-dacs-and-assets-list': (reqParams) => dielApiPost('/get-dacs-and-assets-list', reqParams).then((r) => r.data),
  '/laager/get-alarms-list': (reqParams) => dielApiPost('/laager/get-alarms-list', reqParams).then((r) => r.data),
  '/four-docs/get-login-data': (reqParams) => dielApiPost('/four-docs/get-login-data', reqParams).then((r) => r.data),
  '/overview-card-rooms': (reqParams) => dielApiPost('/overview-card-rooms', reqParams).then((r) => r.data),
  '/overview-rooms-list': (reqParams) => dielApiPost('/overview-rooms-list', reqParams).then((r) => r.data),
  '/energy/get-hist': (reqParams) => dielApiPost('/energy/get-hist', reqParams).then((r) => r.data),
  '/energy/get-hist-infograph': (reqParams) => dielApiPost('/energy/get-hist-infograph', reqParams).then((r) => r.data),
  '/log/query-ddb-table': (reqParams) => dielApiPost('/log/query-ddb-table', reqParams).then((r) => r.data),
  '/dac/get-installation-faults': (reqParams) => dielApiPost('/dac/get-installation-faults', reqParams).then((r) => r.data),
  '/dac/falha-instalacao-detectada': (reqParams) => dielApiPost('/dac/falha-instalacao-detectada', reqParams).then((r) => r.data),
  '/invoice/get-invoices': (reqParams) => dielApiPost('/invoice/get-invoices', reqParams).then((r) => r.data),
  '/invoice/get-invoice-pdf': (reqParams) => dielApiPost('/invoice/get-invoice-pdf', reqParams).then((r) => r.data),
  '/unit/download-sketches': (reqParams) => dielApiPost('/unit/download-sketches', reqParams).then((r) => r.data),
  '/get-assets-sheet-manual': (reqParams) => dielApiPost('/get-assets-sheet-manual', reqParams).then((r) => r.data),
  '/coolautomation/debug-alerts': (reqParams) => dielApiPost('/coolautomation/debug-alerts', reqParams).then((r) => r.data),
  '/realtime/devtools/getServersStatus': (reqParams) => dielApiPost('/realtime/devtools/getServersStatus', reqParams).then((r) => r.data),
  '/clients/get-distributors': (reqParams) => dielApiPost('/clients/get-distributors', reqParams).then((r) => r.data),
  '/clients/add-access-distributor': (reqParams) => dielApiPost('/clients/add-access-distributor', reqParams).then((r) => r.data),
  '/clients/edit-access-distributor': (reqParams) => dielApiPost('/clients/edit-access-distributor', reqParams).then((r) => r.data),
  '/check-client-invoices-batch': (reqParams) => dielApiPost('/check-client-invoices-batch', reqParams).then((r) => r.data),
  '/add-client-invoices-batch': (reqParams) => dielApiPost('/add-client-invoices-batch', reqParams).then((r) => r.data),
  '/clients/get-baseline-templates': (reqParams) => dielApiPost('/clients/get-baseline-templates', reqParams).then((r) => r.data),
  '/clients/get-baseline-info': (reqParams) => dielApiPost('/clients/get-baseline-info', reqParams).then((r) => r.data),
  '/clients/add-baseline-values': (reqParams) => dielApiPost('/clients/add-baseline-values', reqParams).then((r) => r.data),
  '/clients/get-baseline-values': (reqParams) => dielApiPost('/clients/get-baseline-values', reqParams).then((r) => r.data),
  '/clients/edit-baseline-values': (reqParams) => dielApiPost('/clients/edit-baseline-values', reqParams).then((r) => r.data),
  '/clients/add-baseline': (reqParams) => dielApiPost('/clients/add-baseline', reqParams).then((r) => r.data),
  '/clients/set-baseline-values': (reqParams) => dielApiPost('/clients/set-baseline-values', reqParams).then((r) => r.data),
  '/clients/edit-baseline': (reqParams) => dielApiPost('/clients/edit-baseline', reqParams).then((r) => r.data),
  '/clients/get-client-assets-by-group': (reqParams) => dielApiPost('/clients/get-client-assets-by-group', reqParams).then((r) => r.data),
  '/clients/get-client-asset': (reqParams) => dielApiPost('/clients/get-client-asset', reqParams).then((r) => r.data),
  '/clients/get-group-info': (reqParams) => dielApiPost('/clients/get-group-info', reqParams).then((r) => r.data),
  '/invoice/get-invoices-overview': (reqParams) => dielApiPost('/invoice/get-invoices-overview', reqParams).then((r) => r.data),
  '/dev/get-fr-list-updates': (reqParams) => dielApiPost('/dev/get-fr-list-updates', reqParams).then((r) => r.data),
  '/dev/get-fr-list-updates-v2': (reqParams) => dielApiPost('/dev/get-fr-list-updates-v2', reqParams).then((r) => r.data),
  '/sims/send-reset-request': (reqParams) => dielApiPost('/sims/send-reset-request', reqParams).then((r) => r.data),
  '/energy/get-stats': (reqParams) => dielApiPost('/energy/get-stats', reqParams).then((r) => r.data),
  '/energy/get-energy-info': (reqParams) => dielApiPost('/energy/get-energy-info', reqParams).then((r) => r.data),
  '/energy/get-energy-list': (reqParams) => dielApiPost('/energy/get-energy-list', reqParams).then((r) => r.data),
  '/energy/set-energy-info': (reqParams) => dielApiPost('/energy/set-energy-info', reqParams).then((r) => r.data),
  '/energy/set-energy-list-info': (reqParams) => dielApiPost('/energy/set-energy-list-info', reqParams).then((r) => r.data),
  '/energy/delete-energy-info': (reqParams) => dielApiPost('/energy/delete-energy-info', reqParams).then((r) => r.data),
  '/energy/get-energy-combo-opts': (reqParams) => dielApiPost('/energy/get-energy-combo-opts', reqParams).then((r) => r.data),
  '/clients/get-access-distributor-info': (reqParams) => dielApiPost('/clients/get-access-distributor-info', reqParams).then((r) => r.data),
  '/clients/get-baselines-invoice': (reqParams) => dielApiPost('/clients/get-baselines-invoice', reqParams).then((r) => r.data),
  '/dri/delete-dri-info': (reqParams) => dielApiPost('/dri/delete-dri-info', reqParams).then((r) => r.data),
  '/devtools/brokers-monitor-hist-v1': (reqParams) => dielApiPost('/devtools/brokers-monitor-hist-v1', reqParams).then((r) => r.data),
  '/clients/get-machines-list': (reqParams) => dielApiPost('/clients/get-machines-list', reqParams).then((r) => r.data),
  '/clients/get-machines-list-overview': (reqParams) => dielApiPost('/clients/get-machines-list-overview', reqParams).then((r) => r.data),
  '/clients/get-machines-list-overview-v2': (reqParams) => dielApiPost('/clients/get-machines-list-overview-v2', reqParams).then((r) => r.data),
  '/devtools/processDriDay': (reqParams) => dielApiPost('/devtools/processDriDay', reqParams).then((r) => r.data),
  '/devtools/brokers-monitor-disconnected-devs': (reqParams) => dielApiPost('/devtools/brokers-monitor-disconnected-devs', reqParams).then((r) => r.data),
  '/get-integrations-list/laager': (reqParams) => dielApiPost('/get-integrations-list/laager', reqParams).then((r) => r.data),
  '/get-integrations-list/laager-status': (reqParams) => dielApiPost('/get-integrations-list/laager-status', reqParams).then((r) => r.data),
  '/dac/falha-repentina-detectada-v2': (reqParams) => dielApiPost('/dac/falha-repentina-detectada-v2', reqParams).then((r) => r.data),
  '/ecoMode/enter-temperature': (reqParams) => dielApiPost('/ecoMode/enter-temperature', reqParams).then((r) => r.data),
  '/devtools/export-duts-mean-temperatures': (reqParams) => dielApiPost('/devtools/export-duts-mean-temperatures', reqParams).then((r) => r.data),
  '/device-simulator/newMachine': (reqParams) => dielApiPost('/device-simulator/newMachine', reqParams).then((r) => r.data),
  '/device-simulator/listMachines': (reqParams) => dielApiPost('/device-simulator/listMachines', reqParams).then((r) => r.data),
  '/device-simulator/startMachine': (reqParams) => dielApiPost('/device-simulator/startMachine', reqParams).then((r) => r.data),
  '/device-simulator/deleteMachine': (reqParams) => dielApiPost('/device-simulator/deleteMachine', reqParams).then((r) => r.data),
  '/dut/get-dut-schedules': (reqParams) => dielApiPost('/dut/get-dut-schedules', reqParams).then((r) => r.data),
  '/dut/set-dut-schedules': (reqParams) => dielApiPost('/dut/set-dut-schedules', reqParams).then((r) => r.data),
  '/dut/get-dut-exceptions': (reqParams) => dielApiPost('/dut/get-dut-exceptions', reqParams).then((r) => r.data),
  '/dut/set-temperature-sensors': (reqParams) => dielApiPost('/dut/set-temperature-sensors', reqParams).then((r) => r.data),
  '/dut/set-dut-exceptions': (reqParams) => dielApiPost('/dut/set-dut-exceptions', reqParams).then((r) => r.data),
  '/dut/get-compatibility-list': (reqParams) => dielApiPost('/dut/get-compatibility-list', reqParams).then((r) => r.data),
  '/dma/get-dmas-list': (reqParams) => dielApiPost('/dma/get-dmas-list', reqParams).then((r) => r.data),
  '/devtools/processDmaDay': (reqParams) => dielApiPost('/devtools/processDmaDay', reqParams).then((r) => r.data),
  '/devtools/send-email': (reqParams) => dielApiPost('/devtools/send-email', reqParams).then((r) => r.data),
  '/dac/get-fr-history': (reqParams) => dielApiPost('/dac/get-fr-history', reqParams).then((r) => r.data),
  '/dac/get-fault-descs': (reqParams) => dielApiPost('/dac/get-fault-descs', reqParams).then((r) => r.data),
  '/dam/set-local-setpoint': (reqParams) => dielApiPost('/dam/set-local-setpoint', reqParams).then((r) => r.data),
  '/dac/get-countries-list': (reqParams) => dielApiPost('/dac/get-countries-list', reqParams).then((r) => r.data),
  '/devtools/export-duts-temperatures': (reqParams) => dielApiPost('/devtools/export-duts-temperatures', reqParams).then((r) => r.data),
  '/devtools/export-waters-usages': (reqParams) => dielApiPost('/devtools/export-waters-usages', reqParams).then((r) => r.data),
  '/devtools/export-api-private': (reqParams) => dielApiPost('/devtools/export-api-private', reqParams).then((r) => r.data),
  '/get-timezones-list': (reqParams) => dielApiPost('/get-timezones-list', reqParams).then((r) => r.data),
  '/get-timezones-list-with-offset': (reqParams) => dielApiPost('/get-timezones-list-with-offset', reqParams).then((r) => r.data),
  '/dut/get-visibility': (reqParams) => dielApiPost('/dut/get-visibility', reqParams).then((r) => r.data),
  '/unit/get-observations': (reqParams) => dielApiPost('/unit/get-observations', reqParams).then((r) => r.data),
  '/unit/delete-observation': (reqParams) => dielApiPost('/unit/delete-observation', reqParams).then((r) => r.data),
  '/unit/set-observation': (reqParams) => dielApiPost('/unit/set-observation', reqParams).then((r) => r.data),
  '/dut/set-dut-rtype': (reqParams) => dielApiPost('dut/set-dut-rtype', reqParams).then((r) => r.data),
  '/clients/multiple-configs-units': (reqParams) => dielApiPost('dut/set-dut-rtype', reqParams).then((r) => r.data),
  '/dut/set-dut-rtype-v2': (reqParams) => dielApiPost('dut/set-dut-rtype', reqParams).then((r) => r.data),
  '/dut/delete-environments': (reqParams) => dielApiPost('dut/set-dut-rtype', reqParams).then((r) => r.data),
  '/dut/set-visibility': (reqParams) => dielApiPost('/dut/set-visibility', reqParams).then((r) => r.data),
  '/get-model-rates': (reqParams) => dielApiPost('/get-model-rates', reqParams).then((r) => r.data),
  '/create-model-rate': (reqParams) => dielApiPost('/create-model-rate', reqParams).then((r) => r.data),
  '/delete-model-rate': (reqParams) => dielApiPost('/delete-model-rate', reqParams).then((r) => r.data),
  '/load-model-options': (reqParams) => dielApiPost('/load-model-options', reqParams).then((r) => r.data),
  '/create-rate-cicle': (reqParams) => dielApiPost('/create-rate-cicle', reqParams).then((r) => r.data),
  '/delete-rate-cicle': (reqParams) => dielApiPost('/delete-rate-cicle', reqParams).then((r) => r.data),
  '/get-timezone-offset-by-devId': (reqParams) => dielApiPost('/get-timezone-offset-by-devId', reqParams).then((r) => r.data),
  '/laager/set-meter-info': (reqParams) => dielApiPost('/laager/set-meter-info', reqParams).then((r) => r.data),
  '/dmt/get-dmt-nobreak-list': (reqParams) => dielApiPost('/dmt/get-dmt-nobreak-list', reqParams).then((r) => r.data),
  '/dmt/get-dmt-utilities-list': (reqParams) => dielApiPost('/dmt/get-dmt-utilities-list', reqParams).then((r) => r.data),
  '/dmt/set-dmt-nobreak': (reqParams) => dielApiPost('/dmt/set-dmt-nobreak', reqParams).then((r) => r.data),
  '/dal-dam/get-illumination-list': (reqParams) => dielApiPost('/dal-dam/get-illumination-list', reqParams).then((r) => r.data),
  '/dam/get-sched': (reqParams) => dielApiPost('/dam/get-sched', reqParams).then((r) => r.data),
  '/dal/delete-illumination-all-scheds': (reqParams) => dielApiPost('/dal/delete-illumination-all-scheds', reqParams).then((r) => r.data),
  '/dal/delete-illumination-all-exceptions': (reqParams) => dielApiPost('/dal/delete-illumination-all-exceptions', reqParams).then((r) => r.data),
  '/dal/handle-multiple-illumination-sched': (reqParams) => dielApiPost('/dal/handle-multiple-illumination-sched', reqParams).then((r) => r.data),
  '/dmt/set-dmt-illumination': (reqParams) => dielApiPost('/dmt/set-dmt-illumination', reqParams).then((r) => r.data),
  '/dmt/set-dmt-utilities': (reqParams) => dielApiPost('/dmt/set-dmt-utilities', reqParams).then((r) => r.data),
  '/dmt/delete-dmt-nobreak': (reqParams) => dielApiPost('/dmt/delete-dmt-nobreak', reqParams).then((r) => r.data),
  '/dmt/get-dmt-ports-info': (reqParams) => dielApiPost('/dmt/get-dmt-ports-info', reqParams).then((r) => r.data),
  '/dmt/get-nobreaks-charts-data': (reqParams) => dielApiPost('/dmt/get-nobreaks-charts-data', reqParams).then((r) => r.data),
  '/dmt/get-utilities-charts-data': (reqParams) => dielApiPost('/dmt/get-utilities-charts-data', reqParams).then((r) => r.data),
  '/dmt/get-nobreak-info': (reqParams) => dielApiPost('/dmt/get-nobreak-info', reqParams).then((r) => r.data),
  '/dal/get-dal-illumination-list': (reqParams) => dielApiPost('/dal/get-dal-illumination-list', reqParams).then((r) => r.data),
  '/dal/set-dal-illumination': (reqParams) => dielApiPost('/dal/set-dal-illumination', reqParams).then((r) => r.data),
  '/dal/delete-dal-illumination': (reqParams) => dielApiPost('/dal/delete-dal-illumination', reqParams).then((r) => r.data),
  '/dal/get-dal-ports-info': (reqParams) => dielApiPost('/dal/get-dal-ports-info', reqParams).then((r) => r.data),
  '/dal/get-illumination-info': (reqParams) => dielApiPost('/dal/get-illumination-info', reqParams).then((r) => r.data),
  '/dal/get-dal-scheds': (reqParams) => dielApiPost('/dal/get-dal-scheds', reqParams).then((r) => r.data),
  '/dal/add-illumination-multiple-scheds': (reqParams) => dielApiPost('/dal/add-illumination-multiple-scheds', reqParams).then((r) => r.data),
  '/dal/add-illumination-sched': (reqParams) => dielApiPost('/dal/add-illumination-sched', reqParams).then((r) => r.data),
  '/dal/update-illumination-sched': (reqParams) => dielApiPost('/dal/update-illumination-sched', reqParams).then((r) => r.data),
  '/dal/delete-illumination-sched': (reqParams) => dielApiPost('/dal/delete-illumination-sched', reqParams).then((r) => r.data),
  '/dal/add-illumination-exception': (reqParams) => dielApiPost('/dal/add-illumination-exception', reqParams).then((r) => r.data),
  '/dal/update-illumination-exception': (reqParams) => dielApiPost('/dal/update-illumination-exception', reqParams).then((r) => r.data),
  '/dal/delete-illumination-exception': (reqParams) => dielApiPost('/dal/delete-illumination-exception', reqParams).then((r) => r.data),
  '/dal/get-illuminations-charts-data': (reqParams) => dielApiPost('/dal/get-illuminations-charts-data', reqParams).then((r) => r.data),
  '/devices/get-devices-list': (reqParams) => dielApiPost('/devices/get-devices-list', reqParams).then((r) => r.data),
  '/dac/enable-faults-dac': (reqParams) => dielApiPost('/dac/enable-faults-dac', reqParams).then((r) => r.data),
  '/dac/enable-faults': (reqParams) => dielApiPost('/dac/enable-faults', reqParams).then((r) => r.data),
  '/dac/get-enable-faults-all': (reqParams) => dielApiPost('/dac/get-enable-faults-all', reqParams).then((r) => r.data),
  '/unified/export-unified-example': (reqParams) => dielApiPost('/unified/export-unified-example', reqParams).then((r) => r.data),
  '/update-model-rate': (reqParams) => dielApiPost('/update-model-rate', reqParams).then((r) => r.data),
  '/get-rate-cicles': (reqParams) => dielApiPost('/get-rate-cicles', reqParams).then((r) => r.data),
  '/get-rate-cicle': (reqParams) => dielApiPost('/get-rate-cicle', reqParams).then((r) => r.data),
  '/update-rate-cicle': (reqParams) => dielApiPost('/update-rate-cicle', reqParams).then((r) => r.data),
  '/dmt/get-dmt-nobreak-list-unit': (reqParams) => dielApiPost('/dmt/get-dmt-nobreak-list-unit', reqParams).then((r) => r.data),
  '/unit/export-real-time': (reqParams) => dielApiPost('/unit/export-real-time', reqParams).then((r) => r.data),
  '/dmt/get-nobreak-additional-parameters': (reqParams) => dielApiPost('/dmt/get-nobreak-additional-parameters', reqParams).then((r) => r.data),
  '/get-machine-additional-parameters': (reqParams) => dielApiPost('/get-machine-additional-parameters', reqParams).then((r) => r.data),
  '/devices/get-offline-device-info': (reqParams) => dielApiPost('/devices/get-offline-device-info', reqParams).then((r) => r.data),
  '/dut/get-usage-per-day': (reqParams) => dielApiPost('/dut/get-usage-per-day', reqParams).then((r) => r.data),
  '/dut/get-usage-per-month': (reqParams) => dielApiPost('/dut/get-usage-per-month', reqParams).then((r) => r.data),
  '/realtime/devtools/publish_dev_cmd': (reqParams) => dielApiPost('/realtime/devtools/publish_dev_cmd', reqParams).then((r) => r.data),
  '/realtime/devtools/get_uNotifs': (reqParams) => dielApiPost('/realtime/devtools/get_uNotifs', reqParams).then((r) => r.data),
  '/realtime/devtools/get_damEcoMonitor': (reqParams) => dielApiPost('/realtime/devtools/get_damEcoMonitor', reqParams).then((r) => r.data),
  '/realtime/devtools/buildCoolAutNotifs': (reqParams) => dielApiPost('/realtime/devtools/buildCoolAutNotifs', reqParams).then((r) => r.data),
  '/realtime/devtools/debug_dam_ecomode': (reqParams) => dielApiPost('/realtime/devtools/debug_dam_ecomode', reqParams).then((r) => r.data),
  '/asset/get-health-status': (reqParams) => dielApiPost('/asset/get-health-status', reqParams).then((r) => r.data),
  '/asset/save-health-info': (reqParams) => dielApiPost('/asset/save-health-info', reqParams).then((r) => r.data),
  '/asset/get-health-hist': (reqParams) => dielApiPost('/asset/get-health-hist', reqParams).then((r) => r.data),
  '/asset/delete-health-hist': (reqParams) => dielApiPost('/asset/delete-health-hist', reqParams).then((r) => r.data),
  '/asset/list-enabled-faults': (reqParams) => dielApiPost('/asset/list-enabled-faults', reqParams).then((r) => r.data),
  '/asset/enable-faults': (reqParams) => dielApiPost('/asset/enable-faults', reqParams).then((r) => r.data),
  '/asset/save-observation-info': (reqParams) => dielApiPost('/asset/save-observation-info', reqParams).then((r) => r.data),
  '/asset/edit-observation-info': (reqParams) => dielApiPost('/asset/edit-observation-info', reqParams).then((r) => r.data),
  '/asset/get-observation': (reqParams) => dielApiPost('/asset/get-observation', reqParams).then((r) => r.data),
  '/asset/delete-observation': (reqParams) => dielApiPost('/asset/delete-observation', reqParams).then((r) => r.data),
  '/dut/get-duts-duo-energy-efficiency': (reqParams) => dielApiPost('/dut/get-duts-duo-enegy-efficiency', reqParams).then((r) => r.data),
  '/dut/get-day-consumption': (reqParams) => dielApiPost('/dut/get-day-consumption', reqParams).then((r) => r.data),
  '/device-simulator/newSimulation': (reqParams) => dielApiPost('/device-simulator/newSimulation', reqParams).then((r) => r.data),
  '/device-simulator/listSimulations': (reqParams) => dielApiPost('/device-simulator/listSimulations', reqParams).then((r) => r.data),
  '/device-simulator/startSimulation': (reqParams) => dielApiPost('/device-simulator/startSimulation', reqParams).then((r) => r.data),
  '/device-simulator/deleteSimulation': (reqParams) => dielApiPost('/device-simulator/deleteSimulation', reqParams).then((r) => r.data),
  '/sims/get-info-sim': (reqParams) => dielApiPost('/sims/get-info-sim', reqParams).then((r) => r.data),
  '/energy/get-analysis-list': (reqParams) => dielApiPost('/energy/get-analysis-list', reqParams).then((r) => r.data),
  '/unit/upload-ground-plan': (reqParams) => dielApiPost('/unit/upload-ground-plan', reqParams).then((r) => r.data),
  '/unit/set-points-ground-plan': (reqParams) => dielApiPost('/unit/set-points-ground-plan', reqParams).then((r) => r.data),
  '/unit/list-devs-unit': (reqParams) => dielApiPost('/unit/list-devs-unit', reqParams).then((r) => r.data),
  '/energy/get-analysis-models-list': (reqParams) => dielApiPost('/energy/get-analysis-models-list', reqParams).then((r) => r.data),
  '/energy/get-energy-analysis-filters': (reqParams) => dielApiPost('/energy/get-energy-analysis-filters', reqParams).then((r) => r.data),
  'energy/get-energy-analysis-hist': (reqParams) => dielApiPost('energy/get-energy-analysis-hist', reqParams).then((r) => r.data),
  'energy/get-energy-analysis-hist-filter': (reqParams) => dielApiPost('energy/get-energy-analysis-hist-filter', reqParams).then((r) => r.data),
  '/unit/get-ground-plans': (reqParams) => dielApiPost('/unit/get-ground-plans', reqParams).then((r) => r.data),
  '/unit/update-ground-plan': (reqParams) => dielApiPost('/unit/update-ground-plan', reqParams).then((r) => r.data),
  '/unit/delete-ground-plan': (reqParams) => dielApiPost('/unit/delete-ground-plan', reqParams).then((r) => r.data),
  '/mainservice/notifications/get-notifications': (reqParams) => dielApiPost('/mainservice/notifications/get-notifications', reqParams).then((r) => r.data),
  '/mainservice/notifications/get-count-notifications': (reqParams) => dielApiPost('/mainservice/notifications/get-count-notifications', reqParams).then((r) => r.data),
  '/mainservice/notifications/view-notification': (reqParams) => dielApiPost('/mainservice/notifications/view-notification', reqParams).then((r) => r.data),
  '/mainservice/notifications/view-all-notifications': (reqParams) => dielApiPost('/mainservice/notifications/view-all-notifications', reqParams).then((r) => r.data),
  '/dri/get-chiller-alarms-list': (reqParams) => dielApiPost('/dri/get-chiller-alarms-list', reqParams).then((r) => r.data),
  '/dri/get-chiller-alarms-list-hist': (reqParams) => dielApiPost('/dri/get-chiller-alarms-list-hist', reqParams).then((r) => r.data),
  '/dri/export-chiller-alarms-hist': (reqParams) => dielApiPost('/dri/export-chiller-alarms-hist', reqParams).then((r) => r.data),
  '/dri/get-all-chiller-alarms-codes': (reqParams) => dielApiPost('/dri/get-all-chiller-alarms-codes', reqParams).then((r) => r.data),
  '/devices/get-config-devices': (reqParams) => dielApiPost('/devices/get-config-devices', reqParams).then((r) => r.data),
  '/dma/get-month-usage': (reqParams) => dielApiPost('/dma/get-month-usage', reqParams).then((r) => r.data),
  '/dma/get-dates-usage': (reqParams) => dielApiPost('/dma/get-dates-usage', reqParams).then((r) => r.data),
  '/clients/get-all-clients': (reqParams) => dielApiPost('/clients/get-all-clients', reqParams).then((r) => r.data),
  '/clients/get-all-units-by-client': (reqParams) => dielApiPost('/clients/get-all-units-by-client', reqParams).then((r) => r.data),
  '/mainservice/get-units-list': (reqParams) => dielApiPost('/mainservice/get-units-list', reqParams).then((r) => r.data),
  '/upload-service/upload-image': (reqParams) => dielApiPost('/upload-service/upload-image', reqParams).then((r) => r.data),
  '/upload-service/get-images': (reqParams) => dielApiPost('/upload-service/get-images', reqParams).then((r) => r.data),
  '/upload-service/delete-image': (reqParams) => dielApiPost('/upload-service/delete-image', reqParams).then((r) => r.data),
  '/upload-service/get-sketches-list': (reqParams) => dielApiPost('/upload-service/get-sketches-list', reqParams).then((r) => r.data),
  '/upload-service/edit-sketch': (reqParams) => dielApiPost('/upload-service/get-sketches-list', reqParams).then((r) => r.data),
  '/upload-service/delete-sketch': (reqParams) => dielApiPost('/upload-service/delete-sketch', reqParams).then((r) => r.data),
  '/upload-service/download-sketches': (reqParams) => dielApiPost('/upload-service/download-sketches', reqParams).then((r) => r.data),
  '/upload-service/upload-sketch': (reqParams) => dielApiPost('/upload-service/upload-sketch', reqParams).then((r) => r.data),
  '/dri/get-chiller-parameters-hist': (reqParams) => dielApiPost('/dri/get-chiller-parameters-hist', reqParams).then((r) => r.data),
  '/energy/get-analysis-model': (reqParams) => dielApiPost('/energy/get-analysis-model', reqParams).then((r) => r.data),
  '/energy/get-energy-analysis-hist': (reqParams) => dielApiPost('/energy/get-energy-analysis-hist', reqParams).then((r) => r.data),
  '/energy/get-energy-analysis-hist-filter': (reqParams) => dielApiPost('/energy/get-energy-analysis-hist-filter', reqParams).then((r) => r.data),
  '/permissions/get-permissions-on-unit': (reqParams) => dielApiPost('/permissions/get-permissions-on-unit', reqParams).then((r) => r.data),
  '/devices/get-client-unit': (reqParams) => dielApiPost('/devices/get-client-unit', reqParams).then((r) => r.data),
  '/simcards/get-client-unit': (reqParams) => dielApiPost('/simcards/get-client-unit', reqParams).then((r) => r.data),
  '/laager/get-client-unit': (reqParams) => dielApiPost('/laager/get-client-unit', reqParams).then((r) => r.data),
  '/dev/falha-repentina-detectada': (reqParams) => dielApiPost('/dev/falha-repentina-detectada', reqParams).then((r) => r.data),
};

type FullApi = API_private & API_public & API_uploadService & API_private_deprecated;
export type ApiResps = {
  [Route in keyof FullApi]: ThenArg<ReturnType<FullApi[Route]>>;
};
export type ApiParams = {
  [Route in keyof FullApi]: Parameters<FullApi[Route]>[0];
};

export function apiCall<Key extends keyof FullApi>(key: Key, reqParams?: Parameters<FullApi[Key]>[0]): ReturnType<FullApi[Key]> {
  return dielApiPost(key, reqParams).then((r) => r.data) as any;
}

export function apiCall2<Key extends keyof FullApi>(route: Key, reqParams: Parameters<FullApi[Key]>[0], authToken: string): ReturnType<FullApi[Key]> {
  const config: AxiosRequestConfig = {
    method: 'post',
    baseURL: __API__,
    url: route,
    data: reqParams,
    headers: {},
  };
  if (authToken && authToken.length > 10) config.headers.Authorization = authToken;
  return axios(config).then((r) => r.data) as any;
  // .catch((e) => {
  //   return checkAxiosError(e);
  // })
}

const apiLegacy = Object.assign(instance, api);

function toFormData(reqParams: { [name: string]: string|Blob|Array<Blob>|Array<string>|null|number|boolean }) {
  const formData = new FormData();
  for (const [propName, propVal] of Object.entries(reqParams)) {
    if (Array.isArray(propVal)) {
      for (const propValItem of propVal) {
        formData.set(propName, propValItem);
      }
    } else if (propVal != null) {
      if (typeof propVal === 'number' || typeof propVal === 'boolean') {
        formData.set(propName, propVal.toString());
      } else {
        formData.set(propName, propVal);
      }
    }
  }
  return formData;
}

export const apiSpecial = {
  '/devs/upload-new-firmware-version': (reqParams: ApiParams['/devs/upload-new-firmware-version']&{ file: Blob }) => apiLegacy.post<ApiResps['/devs/upload-new-firmware-version']>('/devs/upload-new-firmware-version', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/check-client-dacs-batch': (reqParams: ApiParams['/check-client-dacs-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-dacs-batch']>('/check-client-dacs-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/export-client-dacs-batch-input': (reqParams: ApiParams['/export-client-dacs-batch-input']) => apiLegacy.post<ApiResps['/export-client-dacs-batch-input']>('/export-client-dacs-batch-input', reqParams, { responseType: 'blob' }),
  '/check-client-duts-batch': (reqParams: ApiParams['/check-client-duts-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-duts-batch']>('/check-client-duts-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/check-client-dmas-batch': (reqParams: ApiParams['/check-client-dmas-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-dmas-batch']>('/check-client-dmas-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/check-client-dams-batch': (reqParams: ApiParams['/check-client-dams-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-dams-batch']>('/check-client-dams-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/check-client-supervisors-batch': (reqParams: ApiParams['/check-client-supervisors-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-supervisors-batch']>('/check-client-supervisors-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/check-client-units-batch': (reqParams: ApiParams['/check-client-units-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-units-batch']>('/check-client-units-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/check-client-roomtypes-batch': (reqParams: ApiParams['/check-client-roomtypes-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-roomtypes-batch']>('/check-client-roomtypes-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/check-client-assets-batch': (reqParams: ApiParams['/check-client-assets-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-assets-batch']>('/check-client-assets-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/check-client-unified-batch': (reqParams: ApiParams['/check-client-unified-batch']&{ file: Blob }) => apiLegacy.post<ApiResps['/check-client-unified-batch']>('/check-client-unified-batch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/sims/upload-sim-photo': (reqParams: ApiParams['/sims/upload-sim-photo']&{ photo: Blob }) => apiLegacy.post<ApiResps['/sims/upload-sim-photo']>('/sims/upload-sim-photo', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/upload-dri-varscfg': (reqParams: ApiParams['/upload-dri-varscfg']&{ file: Blob }) => apiLegacy.post<ApiResps['/upload-dri-varscfg']>('/upload-dri-varscfg', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/dac/export-unit-report': (reqParams: ApiParams['/dac/export-unit-report']) => apiLegacy.post<ApiResps['/dac/export-unit-report']>('/dac/export-unit-report', reqParams, { responseType: 'blob' }),
  '/dac/export-preventive-report': (reqParams: ApiParams['/dac/export-preventive-report']) => apiLegacy.post<ApiResps['/dac/export-preventive-report']>('/dac/export-preventive-report', reqParams, { responseType: 'blob' }),
  '/export-dacs-faults': (reqParams: ApiParams['/export-dacs-faults']) => apiLegacy.post<ApiResps['/export-dacs-faults']>('/export-dacs-faults', reqParams, { responseType: 'blob' }),
  '/devtools/export-dacs-info': (reqParams: ApiParams['/devtools/export-dacs-info']) => apiLegacy.post<ApiResps['/devtools/export-dacs-info']>('/devtools/export-dacs-info', reqParams, { responseType: 'blob' }),
  '/devtools/export-duts-info': (reqParams: ApiParams['/devtools/export-duts-info']) => apiLegacy.post<ApiResps['/devtools/export-duts-info']>('/devtools/export-duts-info', reqParams, { responseType: 'blob' }),
  '/devtools/export-dams-info': (reqParams: ApiParams['/devtools/export-dams-info']) => apiLegacy.post<ApiResps['/devtools/export-dams-info']>('/devtools/export-dams-info', reqParams, { responseType: 'blob' }),
  '/analise-integrada-export': (reqParams: ApiParams['/analise-integrada-export']) => apiLegacy.post<ApiResps['/analise-integrada-export']>('/analise-integrada-export', reqParams, { responseType: 'blob' }),
  '/unit/export-real-time': (reqParams: ApiParams['/unit/export-real-time']) => apiLegacy.post<ApiResps['/unit/export-real-time']>('/unit/export-real-time', reqParams, { responseType: 'blob' }),
  '/unit/upload-ground-plan': (reqParams: ApiParams['/unit/upload-ground-plan']&{ photo: Blob }) => apiLegacy.post<ApiResps['/unit/upload-ground-plan']>('/unit/upload-ground-plan', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/upload-service/upload-image': (reqParams: ApiParams['/upload-service/upload-image']&{ file: Blob }) => apiLegacy.post<ApiResps['/upload-service/upload-image']>('/upload-service/upload-image', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
  '/upload-service/upload-sketch': (reqParams: ApiParams['/upload-service/upload-sketch']&{ file: Blob }) => apiLegacy.post<ApiResps['/upload-service/upload-sketch']>('/upload-service/upload-sketch', toFormData(reqParams), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data),
};

interface ApiWithUpload {
  '/devs/upload-new-firmware-version': { file: Blob },
  '/check-client-dacs-batch': { file: Blob },
  '/check-client-duts-batch': { file: Blob },
  '/check-client-dmas-batch': { file: Blob },
  '/check-client-dams-batch': { file: Blob },
  '/check-client-dris-batch': { file: Blob },
  '/check-client-supervisors-batch': { file: Blob },
  '/check-client-units-batch': { file: Blob },
  '/check-client-roomtypes-batch': { file: Blob },
  '/check-client-assets-batch': { file: Blob },
  '/check-client-invoices-batch': { file: Blob },
  '/check-client-unified-batch': { file: Blob },
  '/dac/upload-image': { photo: Blob },
  '/dut/upload-image': { photo: Blob },
  '/dam/upload-image': { photo: Blob },
  '/dri/upload-image': { photo: Blob },
  '/dma/upload-image': { photo: Blob },
  '/asset/upload-image': { photo: Blob },
  '/devgroups/upload-image': { photo: Blob },
  '/upload-service/upload-sketch': { file: Blob },
  '/sims/upload-sim-photo': { photo: Blob },
  '/upload-dri-varscfg': { file: Blob|null },
  '/vt/set-vt-info': { PLANTABAIXA_IMG: string[], AUTORIZACAO_IMG: string[] },
  '/vt/update-vt-info': { PLANTABAIXA_IMG: string[], AUTORIZACAO_IMG: string[] },
  '/unit/upload-ground-plan': { photo: Blob },
  '/upload-service/upload-image': { file: Blob }
  '/unit/upload-sketch': { file: Blob },
}
export function apiCallFormData<Key extends keyof ApiWithUpload>(key: Key, reqParams: Parameters<FullApi[Key]>[0], files: ApiWithUpload[Key]) {
  return apiLegacy.post<ApiResps[Key]>(key, toFormData({ ...reqParams, ...files }), { headers: { 'content-type': 'multipart-form-data' } }).then((r) => r.data);
}

interface ApiWithDownload {
  '/export-client-dacs-batch-input': true,
  '/dac/export-unit-report': true,
  '/dac/export-preventive-report': true,
  '/export-dacs-faults': true,
  '/devtools/export-dacs-info': true,
  '/devtools/export-duts-info': true,
  '/devtools/export-dams-info': true,
  '/analise-integrada-export': true,
  '/export-client-assets-batch-input': true,
  '/export-client-unified-batch-input': true,
  '/invoice/get-invoice-pdf': true,
  '/unit/download-sketches': true,
  '/upload-service/download-sketches': true,
  '/devtools/brokers-monitor-disconnected-devs': true,
  '/devtools/export-duts-mean-temperatures': true,
  '/get-assets-sheet-manual': true,
  '/get-private-unit-report-file': true,
  '/devtools/export-duts-temperatures': true,
  '/devtools/export-waters-usages': true,
  '/unified/export-unified-example': true,
  '/unit/export-real-time': true,
  '/dri/export-chiller-alarms-hist': true,
}
export function apiCallDownload<Key extends keyof ApiWithDownload>(key: Key, reqParams: Parameters<FullApi[Key]>[0]) {
  return apiLegacy.post<ApiResps[Key]>(key, reqParams, { responseType: 'blob' });
}

export default apiLegacy; // instance
