import moment from 'moment';
import { useEffect, useState } from 'react';
import { Box, Flex } from 'reflexbox';
import { Carousel } from '~/components';
import { apiCall, ApiResps, apiSpecial } from '~/providers';
import { colors } from '~/styles/colors';
import { Data, DataText } from '../../Assets/AssetInfo/styles';
import { useTranslation } from 'react-i18next';
import { thousandPointFormat } from '~/helpers/thousandFormatNumber';

export const IntegrWaterInfo = (props: {
  devInfo: ApiResps['/get-integration-info']['info'],
  integrId: string,
}): JSX.Element => {
  const { t } = useTranslation();
  const { devInfo, integrId } = props;
  const [bateryState, setBateryState] = useState('');
  const [rfModule, setRfModule] = useState('');

  useEffect(() => {
    (async () => {
      if (!integrId.startsWith('DMA')) {
        const laagerUsage = await apiCall('/laager/get-informations', {
          unit_id: integrId,
        });

        setBateryState(laagerUsage.batery_state);
        setRfModule(laagerUsage.module_rf.toString());
      }
    })();
  }, []);

  async function handleGetAssetImages() {
    if (integrId.startsWith('DMA')) {
      return apiCall('/upload-service/get-images', {
        referenceId: devInfo.DEVICE_ID!,
        referenceType: 'DMAS',
      });
    }
    return apiCall('/upload-service/get-images', {
      referenceId: devInfo.DEVICE_ID!,
      referenceType: 'LAAGER',
    });
  }
  async function handlePostAssetImage(photo: Blob) {
    if (integrId.startsWith('DMA')) {
      return apiSpecial['/upload-service/upload-image'](
        {
          referenceId: devInfo.DEVICE_ID!,
          referenceType: 'DMAS',
          file: photo,
        },
      );
    }

    return apiSpecial['/upload-service/upload-image'](
      {
        referenceId: devInfo.DEVICE_ID!,
        referenceType: 'LAAGER',
        file: photo,
      },
    );
  }
  async function handleDeleteAssetImage(imageUrl: string) {
    if (integrId.startsWith('DMA')) {
      return apiCall('/upload-service/delete-image', {
        referenceId: devInfo.DEVICE_ID!,
        referenceType: 'DMAS',
        filename: imageUrl,
      });
    }

    return apiCall('/upload-service/delete-image', {
      referenceId: devInfo.DEVICE_ID!,
      referenceType: 'LAAGER',
      filename: imageUrl,
    });
  }

  const handleDate = () => (
    devInfo.installationDate ? moment(devInfo.installationDate?.substring(0, 10)).format('ll') === t('dataInvalida') ? '-' : moment(devInfo.installationDate?.substring(0, 10)).format('ll') : '-'
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">

          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {integrId === '' ? 'Ambiente virtual sem Dispositivo' : integrId.startsWith('DMA') ? 'DMA ID' : t('idDaUnidadeLaager')}
            </DataText>
            <DataText>{devInfo.integrId || '-'}</DataText>
          </Data>

          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {t('fabricante')}
            </DataText>
            <DataText>{devInfo.supplier || '-'}</DataText>
          </Data>

          {!integrId.startsWith('DMA') && (
          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {t('moduloRf')}
            </DataText>
            <DataText>{rfModule || '-'}</DataText>
          </Data>
          )}

          {!integrId.startsWith('DMA') && (
          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {t('statusBateria')}
            </DataText>
            <DataText>{bateryState || '-'}</DataText>
          </Data>
          )}

          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {t('hidrometro')}
            </DataText>
            <DataText>{ devInfo.hydrometerModel || '-'}</DataText>
          </Data>

          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {t('localDeInstalacao')}
            </DataText>
            <DataText>{devInfo.installationLocation || '-'}</DataText>
          </Data>

          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {t('dataInstalacao')}
            </DataText>
            <DataText>{handleDate()}</DataText>
          </Data>

          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {t('capacidadeTotalReservatorios')}
            </DataText>
            <DataText>{thousandPointFormat(devInfo.totalCapacity) || '-'}</DataText>
          </Data>

          <Data>
            <DataText color={colors.Grey300} fontWeight="bold">
              {t('totalDeReservatorios')}
            </DataText>
            <DataText>{devInfo.quantityOfReservoirs || '-'}</DataText>
          </Data>
        </Flex>

        <Box width={[1, 1, 1, 1, 1, 1 / 2]}>
          <Flex
            flexDirection="column"
            alignItems={['center', 'center', 'center', 'center', 'flex-end', 'flex-end']}
          >
            {integrId !== '' && (
            <Box width={1} maxWidth="415px" mb="16px" justifyContent="center" alignItems="center">
              <Carousel
                getImages={handleGetAssetImages}
                postImage={handlePostAssetImage}
                deleteImage={handleDeleteAssetImage}
              />
            </Box>
            )}

          </Flex>
        </Box>
      </Flex>
    </div>
  );
};
