import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { t, use } from 'i18next';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';

import { Header, Loader } from 'components';
import { getUserProfile } from 'helpers/userProfile';
import { useStateVar } from 'helpers/useStateVar';
import { apiCall } from 'providers';
import { colors } from 'styles/colors';

import { Headers2 } from '../../Header';
import { getCachedDevInfo, getCachedDevInfoSync } from '~/helpers/cachedStorage';
import { DevLayout } from '../../DEVs/DevLayout';
import { AssetLayout } from '../../Assets/AssetLayout';
import { Health } from './Health';
import { ManagementFaults } from './ManagementFaults';
import { withTransaction } from '@elastic/apm-rum-react';

export const DacHealth = (): JSX.Element => {
  const { devId } = useParams<{ devId: string }>();
  const routeParams = useParams<{ unitId: string }>();
  const history = useHistory();
  const [profile] = useState(getUserProfile);
  const [state, render, setState] = useStateVar({
    devInfo: getCachedDevInfoSync(devId),
    isLoading: false,
    assetLayout: false as boolean,
  });

  const queryPars = queryString.parse(history.location.search);
  const linkBase = history.location.pathname;

  const allTabs = [
    {
      title: t('geral'),
      link: `${linkBase}?${queryString.stringify({ ...queryPars, aba: 'geral' })}`,
      isActive: (queryPars.aba === 'geral') || (!queryPars.aba),
      visible: true,
      ref: useRef(null),
    },
    {
      title: t('gerenciamentoDeFalhas'),
      link: `${linkBase}?${queryString.stringify({ ...queryPars, aba: 'gerenciamento-falhas' })}`,
      isActive: (queryPars.aba === 'gerenciamento-falhas'),
      visible: !!profile.manageAllClients,
      ref: useRef(null),
    },
  ];

  async function getDevInfo() {
    if (!state.devInfo) {
      try {
        state.devInfo = await getCachedDevInfo(devId, {});
      } catch (err) {
        console.log(err);
        toast.error(t('houveErro'));
      }
    }
    setState({ isLoading: false });
  }

  useEffect(() => {
    getDevInfo();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('dielEnergiaSaude')}</title>
      </Helmet>
      <AssetLayout devInfo={state.devInfo} />
      <br />
      {(allTabs.length > 1) && (
        <div style={{ width: 'fit-content' }}>
          <Headers2 links={allTabs} />
        </div>
      )}
      <br />
      {(state.isLoading) && (
        <Loader />
      )}
      {(!state.isLoading && allTabs[0].isActive && state.devInfo) && (
        <Health />
      )}
      {(!state.isLoading && allTabs[1].isActive && state.devInfo) && (
        <ManagementFaults devId={devId} unitId={state.devInfo.UNIT_ID} assetId={state.devInfo.ASSET_ID} />
      )}
    </>
  );
};

export default withTransaction('DacHealth', 'component')(DacHealth);
