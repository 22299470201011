import { Header } from '../../../Analysis/Header';

export const ProjetoBBLayout = ({ ...props }): JSX.Element => {
  const links = [
    {
      link: '/painel/projeto-bb/kpis',
      title: 'KPIs do Projeto',
    },
    {
      link: '/painel/projeto-bb/ans',
      title: 'ANS',
    },
    {
      link: '/painel/projeto-bb/cronograma',
      title: 'Cronograma de Instalação',
    },
  ];

  return (
    <>
      <Header links={links} />
    </>
  );
};
