/* eslint-disable import/no-duplicates */

import { useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import moment, { isMoment } from 'moment';
import DatePicker from 'react-datepicker';
import i18n from '~/i18n';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import { toast } from 'react-toastify';
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Legend,
  Cell,
} from 'recharts';
import { Flex, Box } from 'reflexbox';
import {
  Select, Card, Loader, Checkbox,
} from '~/components';
import { useStateVar } from '~/helpers/useStateVar';
import { apiCall, ApiResps } from '~/providers';

import {
  BtnMonthView,
  ContentDate,
  CustomLegendLi,
  CustomLegendUl,
  Label,
  MonthViewBtnText,
  StyledCalendarIcon,
  StyledCalendarIconMonthViewBtn,
  VarContainer,
  VarName,
  VarUnit,
  VarValue,
  WaterTooltip,
} from './styles';
import { ToggleSwitchMini } from '~/components/ToggleSwitch';
import IconOffline from 'assets/img/status_offline.svg';
import IconOnline from 'assets/img/status_online.svg';
import { addDays_YMD } from '~/helpers/formatTime';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface IGraphData {
  name: string;
  usage: string;
  date: string;
  estimatedUsage?: boolean;
  dailyReadings?: {
    name: string;
    usage: string;
    date: string;
    dayUsage: number | string;
    readTime: string;
    estimatedUsage?: boolean;
  }[],
}

const jsWeekNumberToForecastIndex = {
  0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5,
};

export function WaterHistory(props: {
  unit_id: string;
  status: string | undefined;
  installationDate: string | undefined | null;
}): JSX.Element {
  const { t } = useTranslation();
  moment.locale(i18n.language === 'pt' ? 'pt-BR' : 'en');
  const { unit_id, status } = props;
  const [shouldNotDisplayLiters, setShouldNotDisplayLiters] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMonthBtnHovered, setIsMonthBtnHovered] = useState(false);
  const [state, render, setState] = useStateVar({
    loading: true,
    loadingNewData: true,
    focused: false,
    focusedInput: null,
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    graphData: [] as IGraphData[],
    graphDataForecast: [] as IGraphData[],
    cubicMetersGraphData: [] as IGraphData[],
    cubicMetersGraphDataForecast: [] as IGraphData[],
    graphLegend: [] as string[],
    showForecast: true,
    period: t('mes'),
    periodIsMonthOrFlexible: true,
    maxUsage: 0,
    lastReadingDate: '',
    daily_average: '0',
    period_usage: '0',
    predicted: '0',
    installationDate: props.installationDate as (string | undefined | null),
    devInfo: null as (null|ApiResps['/get-integration-info']['info']),
    forecast: null as (null|ApiResps['/dma/get-dma-mean-history']),
    isCurrentMonth: false as boolean,
    consumptionByDayOfWeek: [
      { label: t('diasDaSemana.domingo'), days: 0, consumption: 0 },
      { label: t('diasDaSemana.segunda'), days: 0, consumption: 0 },
      { label: t('diasDaSemana.terca'), days: 0, consumption: 0 },
      { label: t('diasDaSemana.quarta'), days: 0, consumption: 0 },
      { label: t('diasDaSemana.quinta'), days: 0, consumption: 0 },
      { label: t('diasDaSemana.sexta'), days: 0, consumption: 0 },
      { label: t('diasDaSemana.sabado'), days: 0, consumption: 0 },
    ] as any[],
  });

  const maxValue = (graphData) => {
    let numberArr = [];
    if (state.period === t('dia')) {
      const currentDay = moment(state.startDate).format('DD/MM/YYYY');

      const currentDayData = graphData.filter((e) => e.date === currentDay)[0];

      numberArr = currentDayData?.dailyReadings?.map(({ dayUsage }) => Number(dayUsage));
    } else {
      numberArr = graphData.map(({ usage }) => Number(usage));
    }

    const maxNumber = !numberArr ? 0 : Math.max(...numberArr);

    return maxNumber;
  };

  const setForecastStates = () => {
    const currDate = new Date(Date.now() - 3 * 60 * 60 * 1000);
    const dataDate = new Date(state.endDate);
    state.isCurrentMonth = currDate.getMonth() === dataDate.getMonth() && dataDate.getDate() >= currDate.getDate();
    state.showForecast = true;
  };

  const getDailyData = async () => {
    let responseUsage;
    if (unit_id.startsWith('DMA')) {
      if (state.devInfo && state.devInfo.hydrometerModel) {
        responseUsage = await apiCall('/dma/get-day-usage', {
          dmaId: unit_id,
          dayYMD: state.startDate,
        });
      } else if (state.devInfo && !state.devInfo.hydrometerModel) {
        toast.error(t('erroHistoricoHidrometro'));
        setState({ loadingNewData: false });
        return;
      } else {
        setState({ loadingNewData: false });
        return;
      }
    } else {
      responseUsage = await apiCall('/laager/get-daily-usage-history', {
        unit_id,
        start_date: state.startDate,
        end_date: state.startDate,
      });
    }

    const graphData = responseUsage.history.map((h) => {
      const name = format(parseISO(h.information_date), 'dd-eeeee', {
        locale: pt,
      }).toUpperCase();
      const usage = `${h.usage}`;
      const date = format(parseISO(h.information_date), 'dd/MM/yyyy', {
        locale: pt,
      });
      const dailyReadings = (h.readings_per_day && h.readings_per_day.length > 0)
        ? h.readings_per_day.map((readings) => ({
          name,
          usage,
          date,
          dayUsage: readings.usage,
          readTime: readings.time,
          devId: h.devId,
          estimatedUsage: h.estimatedUsage,
        })) : [];

      return {
        name: format(parseISO(h.information_date), 'dd-eeeee', {
          locale: pt,
        }).toUpperCase(),
        usage: `${h.usage}`,
        date: format(parseISO(h.information_date), 'dd/MM/yyyy', {
          locale: pt,
        }),
        dailyReadings,
        estimatedUsage: h.estimatedUsage,
      };
    });
    const maxUsage = maxValue(graphData);
    setState({
      graphData,
      maxUsage,
      daily_average: `${responseUsage.daily_average || '0'}`,
      period_usage: `${responseUsage.period_usage || '0'}`,
      predicted: `${responseUsage.predicted || '0'}`,
    });
  };

  const getResponseUsage = async () => {
    let responseUsage;
    if (unit_id.startsWith('DMA')) {
      if (state.devInfo && state.devInfo.hydrometerModel) {
        responseUsage = await apiCall('/dma/get-usage-history', {
          dmaId: unit_id,
          start_date: state.startDate,
          end_date: state.endDate,
        });
      } else if (state.devInfo && !state.devInfo.hydrometerModel) {
        toast.error(t('erroHistoricoHidrometro'));
        setState({ loadingNewData: false });
        return null;
      } else {
        setState({ loadingNewData: false });
        return null;
      }
    } else {
      responseUsage = await apiCall('/laager/get-usage-history', {
        unit_id,
        start_date: state.startDate,
        end_date: state.endDate,
      });
    }
    return responseUsage;
  };

  const getForecast = async () => {
    try {
      if (unit_id.startsWith('DMA')) {
        const forecast = await apiCall('/dma/get-dma-mean-history', { dmaId: unit_id });
        state.forecast = forecast;
      } else {
        const forecast = await apiCall('/laager/get-meter-mean-history', { meterId: unit_id });
        state.forecast = forecast;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getValueOrZero = (value: any) => `${value || '0'}`;

  const getGraphDataAndForecast = async () => {
    const responseUsage = await getResponseUsage();
    if (responseUsage === null) return null;
    const graphData = responseUsage.history.map((h) => ({
      name: format(parseISO(h.information_date), 'dd-eeeee', {
        locale: pt,
      }).toUpperCase(),
      usage: `${h.usage}`,
      date: format(parseISO(h.information_date), 'dd/MM/yyyy', {
        locale: pt,
      }),
      devId: h.devId,
      estimatedUsage: h.estimatedUsage,
    }));

    setState({
      graphData,
      daily_average: getValueOrZero(responseUsage.daily_average),
      period_usage: getValueOrZero(responseUsage.period_usage),
    });

    let maxUsageForecast = 0;
    let predictedConsumption = 0;
    if (state.periodIsMonthOrFlexible && state.isCurrentMonth) {
      await getForecast();

      if (state.forecast) {
        let graphDataForecast = JSON.parse(JSON.stringify(graphData));
        graphDataForecast = graphDataForecast.map((e) => {
          const auxDate = e.date.split('/');
          e.date = [auxDate[1], auxDate[0], auxDate[2]].join('/');
          const currDate = new Date();
          currDate.setHours(0, 0, 0, 0);
          const dataDate = new Date(e.date);
          dataDate.setHours(0, 0, 0, 0);
          if (dataDate > currDate && state.forecast) {
            e.usage = state.forecast[jsWeekNumberToForecastIndex[dataDate.getDay()]].averageConsumption;
          } else {
            e.usage = 0;
          }
          return {
            devId: e.devId,
            name: e.name,
            usage: e.usage,
            date: e.date,
          };
        });

        setState({ graphDataForecast });

        state.graphData.forEach((e) => predictedConsumption += Number(e.usage));
        state.graphDataForecast.forEach((e) => predictedConsumption += Number(e.usage));
        maxUsageForecast = maxValue(graphDataForecast);
      }
    }

    const maxUsage = Math.max(maxValue(graphData), maxUsageForecast);
    setState({
      predicted: state.periodIsMonthOrFlexible && state.isCurrentMonth ? `${predictedConsumption || '0'}` : `${responseUsage.predicted || '0'}`,
      maxUsage,
    });
  };

  const getOthersPeriodData = async () => {
    await getGraphDataAndForecast();

    if (state.periodIsMonthOrFlexible) {
      const consumptionByDayOfWeek = [
        { label: t('diasDaSemana.domingo'), days: 0, consumption: 0 },
        { label: t('diasDaSemana.segunda'), days: 0, consumption: 0 },
        { label: t('diasDaSemana.terca'), days: 0, consumption: 0 },
        { label: t('diasDaSemana.quarta'), days: 0, consumption: 0 },
        { label: t('diasDaSemana.quinta'), days: 0, consumption: 0 },
        { label: t('diasDaSemana.sexta'), days: 0, consumption: 0 },
        { label: t('diasDaSemana.sabado'), days: 0, consumption: 0 },
      ];
      state.graphData.forEach((e) => {
        const dateArray = e.date.split('/');
        const strDate = [dateArray[1], dateArray[0], dateArray[2]].join('/');
        const date = new Date(strDate);
        const currDate = new Date();
        if (date < currDate) {
          consumptionByDayOfWeek[date.getDay()].consumption += Number(e.usage);
          consumptionByDayOfWeek[date.getDay()].days += 1;
        }
      });
      setState({ consumptionByDayOfWeek });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!state.endDate) return;
        setState({ loadingNewData: true });

        setForecastStates();

        if (state.period === t('dia')) {
          await getDailyData();
        } else {
          await getOthersPeriodData();
        }
      } catch (err) {
        toast.error(t('erroHistorico'));
        console.error(err);
        console.log(err);
      }
      setState({ loadingNewData: false });
    })();
  }, [unit_id, state.startDate, state.devInfo, state.endDate]);

  useEffect(() => {
    (async () => {
      try {
        setState({ loading: true });

        let lastReadingDate = t('erroNaoFoiRealizadaNenhumaLeitura');
        if (unit_id.startsWith('DMA')) {
          const { info } = await apiCall('/get-integration-info', { supplier: 'diel-dma', integrId: unit_id });
          setState({ devInfo: info });
          setState({ installationDate: moment(info.installationDate?.substring(0, 10)).format('ll') === t('dataInvalida') ? '-' : moment(info.installationDate?.substring(0, 10)).toISOString() });
          const response = await apiCall('/dma/get-last-reading-date', { dmaId: unit_id });
          if (response && response.date != null) {
            lastReadingDate = moment(response.date).format(
              'lll',
            );
          }
          try {
            const forecast = await apiCall('/dma/get-dma-mean-history', { dmaId: unit_id });
            state.forecast = forecast;
          } catch (err) {
            console.error(err);
          }
        } else {
          const response = await apiCall('/laager/get-informations', {
            unit_id,
          });
          if (response && response.last_reading_date) {
            lastReadingDate = moment(response.last_reading_date).format(
              'lll',
            );
          }
          try {
            const forecast = await apiCall('/laager/get-meter-mean-history', { meterId: unit_id });
            state.forecast = forecast;
          } catch (err) {
            console.error(err);
          }
        }

        setState({
          lastReadingDate,
        });
      } catch (err) {
        toast.error(t('erroCarregarInformacoes'));
        console.error(err);
      }
      setState({ loading: false });
    })();
  }, []);

  useEffect(() => {
    if (shouldNotDisplayLiters) {
      const cubicMetersHistGraphData = state.graphData.map(
        ({
          name, usage, date, dailyReadings, estimatedUsage,
        }) => {
          const dailyHistReadings = (dailyReadings && dailyReadings.length > 0)
            ? dailyReadings.map((readings) => ({
              name,
              usage: (Number(usage) / 1000).toFixed(2),
              date,
              dayUsage: (Number(readings.dayUsage) / 1000).toFixed(2),
              readTime: readings.readTime,
              estimatedUsage: readings.estimatedUsage,
            })) : [];
          return {
            name,
            usage: (Number(usage) / 1000).toFixed(2),
            date,
            dailyReadings: dailyHistReadings,
            estimatedUsage,
          };
        },
      );
      state.cubicMetersGraphData = cubicMetersHistGraphData;

      let maxUsageForecast = 0;
      if (state.periodIsMonthOrFlexible) {
        const cubicMetersHistGraphDataForecast = state.graphDataForecast.map(
          ({
            name, usage, date, dailyReadings, estimatedUsage,
          }) => {
            const dailyHistReadings = (dailyReadings && dailyReadings.length > 0)
              ? dailyReadings.map((readings) => ({
                name,
                usage: (Number(usage) / 1000).toFixed(2),
                date,
                dayUsage: (Number(readings.dayUsage) / 1000).toFixed(2),
                readTime: readings.readTime,
                estimatedUsage: readings.estimatedUsage,
              })) : [];

            return {
              name,
              usage: (Number(usage) / 1000).toFixed(2),
              date,
              dailyReadings: dailyHistReadings,
              estimatedUsage,
            };
          },
        );
        state.cubicMetersGraphDataForecast = cubicMetersHistGraphDataForecast;
        maxUsageForecast = maxValue(state.cubicMetersGraphData);
      }

      const maxUsage = Math.max(maxValue(state.cubicMetersGraphData), maxUsageForecast);
      setState({ maxUsage });
    } else {
      const maxUsage = maxValue(state.graphData);
      setState({ maxUsage });
    }
    render();
  }, [shouldNotDisplayLiters]);

  useEffect(() => {
    let maxUsage = 0;
    if (state.showForecast) {
      if (shouldNotDisplayLiters) {
        maxUsage = Math.max(maxValue(state.cubicMetersGraphData), maxValue(state.cubicMetersGraphDataForecast));
      } else {
        maxUsage = Math.max(maxValue(state.graphData), maxValue(state.graphDataForecast));
      }
    } else if (shouldNotDisplayLiters) {
      maxUsage = maxValue(state.cubicMetersGraphData);
    } else {
      maxUsage = maxValue(state.graphData);
    }
    setState({ maxUsage });
  }, [state.showForecast]);

  function handleDateChange(date: moment.Moment | string): void {
    if (state.period === t('dia') && isMoment(date)) {
      const formattedDate = date.format('YYYY-MM-DD');
      setState({ startDate: formattedDate, endDate: formattedDate });
    }
    else if (state.period === t('semana') && isMoment(date)) {
      const startDate = date.startOf('week').format('YYYY-MM-DD');
      const endDate = date.endOf('week').format('YYYY-MM-DD');
      setState({ startDate, endDate });
    }
    else if (state.period === t('mes') && isMoment(date)) {
      const startDate = date.startOf('month').format('YYYY-MM-DD');
      const endDate = date.endOf('month').format('YYYY-MM-DD');
      setState({ startDate, endDate });
    }
    else if (state.period === t('flexivel') && isMoment(date)) {
      const startDate = date.startOf('month').format('YYYY-MM-DD');
      const endDate = date.endOf('month').format('YYYY-MM-DD');

      setState({ startDate, endDate });
    }
    setShouldNotDisplayLiters(false);
  }

  const CustomizedAxisTick = ({
    x, y, _, payload,
  }: any) => (
    <g transform={`translate(${x},${y})`} height="50px">
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        className="recharts-text"
      >
        <tspan x="0" dy="1.2em" dx="0.5em">
          {payload.value.split('-')?.[0]}
        </tspan>
        <tspan x="0" dy="1.2em" dx="0.5em" className="recharts-text-strong">
          {payload.value.split('-')?.[1]}
        </tspan>
      </text>
    </g>
  );

  const CustomizedDailyAxisTick = ({
    x, y, _, payload,
  }: any) => (
    <g transform={`translate(${x},${y})`} height="50px">
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        className="recharts-text"
      >
        <tspan x="0" dy="1.2em" dx="1.2em">
          {payload.value}
        </tspan>
      </text>
    </g>
  );

  const renderLegendText = () => (
    <CustomLegendUl>
      <CustomLegendLi>
        {shouldNotDisplayLiters ? t('consumoM3') : t('consumoL')}
      </CustomLegendLi>
    </CustomLegendUl>
  );

  const getMeasureUnit = () => (shouldNotDisplayLiters ? 'm³' : t('litros'));

  const CustomizedToolTip = ({ payload }: any) => {
    if (payload != null && payload[0]?.payload?.date) {
      let usage = state.period === t('dia') ? payload[0]?.payload?.dayUsage : payload[0]?.payload?.usage;
      const hour = state.period === t('dia') ? payload[0]?.payload?.readTime : '';
      let isForecast = true;

      const auxDate = payload[0]?.payload?.date;
      const dateArray = auxDate.split('/');
      const strDate = [dateArray[1], dateArray[0], dateArray[2]].join('/');
      const devId = payload[0]?.payload?.devId;

      if (state.periodIsMonthOrFlexible && state.showForecast && state.forecast && state.isCurrentMonth && payload[0]?.payload?.date) {
        const currDate = new Date();
        currDate.setHours(0, 0, 0, 0);
        const dataDate = new Date(auxDate);
        dataDate.setHours(0, 0, 0, 0);

        if (dataDate <= currDate) {
          isForecast = false;

          if (shouldNotDisplayLiters) {
            usage = state.cubicMetersGraphData.find((e) => e.date === strDate)?.usage || 0;
          } else {
            usage = state.graphData.find((e) => e.date === strDate)?.usage || 0;
          }
        }
      }

      const currDayOfWeekInfo = state.consumptionByDayOfWeek[new Date(state.isCurrentMonth && state.forecast && state.showForecast ? auxDate : strDate).getDay()];
      return (
        <WaterTooltip>
          <span>{devId?.startsWith('DMA') ? t('dispositivo') : t('medidorAguaLaager')}</span>
          <p>{devId}</p>
          <span>{ state.periodIsMonthOrFlexible && state.showForecast && state.forecast && isForecast && state.isCurrentMonth ? t('previsao') : t('leitura')}</span>
          <p>
            <strong>
              { shouldNotDisplayLiters ? (payload && payload?.length > 0) && Number(usage).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) : (payload && payload?.length > 0) && Number(usage).toLocaleString('pt-BR', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }) || '0'}
            </strong>
            <span>{getMeasureUnit()}</span>
          </p>
          {state.periodIsMonthOrFlexible ? (
            <>
              <span>{t('mediasDeConsumo')}</span>
              <br />
              <span>{`(${currDayOfWeekInfo?.label})`}</span>
              <p>
                <strong>
                  { shouldNotDisplayLiters
                    ? `${Number(((Number(currDayOfWeekInfo?.consumption) / (currDayOfWeekInfo?.days || 1)) / 1000).toFixed(2)).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                    : `${Number((Number(currDayOfWeekInfo?.consumption) / (currDayOfWeekInfo?.days || 1))).toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`}
                </strong>
                <span>{getMeasureUnit()}</span>
              </p>
            </>
          ) : null}
          <span>{`${t('data')}:`}</span>
          <div>{(payload && payload?.length > 0) && `${state.periodIsMonthOrFlexible && state.forecast && state.showForecast && state.isCurrentMonth ? strDate : payload[0]?.payload?.date} ${hour}` || moment().format('DD/MM/YYYY')}</div>
        </WaterTooltip>
      );
    }
    return <div />;
  };

  const handleBarClick = async (data) => {
    if (state.period !== t('dia')) {
      const dateToStr = data.date && `${data.date}`;
      const [day, month, year] = dateToStr.split('/');
      const formattedDate = `${year}-${month}-${day}`;
      setState({ period: t('dia') });
      handleDateChange(moment(formattedDate));
    }
  };

  const defineGraphData = () => {
    if (state.period === t('dia')) {
      try {
        return shouldNotDisplayLiters
          ? state.cubicMetersGraphData.map((data) => data?.dailyReadings)[0]
          : state.graphData.map((data) => data?.dailyReadings)[0];
      } catch (err) {
        toast.warn(t('erroRecuperarInformacoesDia'));
        return [];
      }
    }
    else {
      return shouldNotDisplayLiters
        ? state.cubicMetersGraphData
        : state.graphData;
    }
  };

  const getStatusIcon = (status?: string) => (status === 'ONLINE' ? IconOnline : IconOffline);

  const formatConsumption = (num: string) => (
    shouldNotDisplayLiters
      ? (Number(num) / 1000).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      : Number(num).toLocaleString('pt-BR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
  );

  const setCursor = () => (isHovered ? { cursor: 'pointer' } : { cursor: 'default' });

  const getGraphDataKey = () => (state.period === t('dia') ? 'readTime' : 'name');

  const getGraphTick = () => (state.period === t('dia') ? <CustomizedDailyAxisTick /> : <CustomizedAxisTick />);

  const handleMonthView = () => {
    setState({ period: t('mes') });
    handleDateChange(moment(state.startDate));
  };

  return (
    <div style={{ paddingTop: '25px' }}>
      {(state.loading || state.loadingNewData) && <Loader />}
      {(!state.loading && !state.loadingNewData) && (
        <>
          <Box width={1} pt={20}>
            <Flex flexWrap="wrap" alignItems="center">
              <Box width={[1, 1, 1, 1, 1, 1 / 5]}>
                <Select
                  options={[t('dia'), t('semana'), t('mes'), t('flexivel')]}
                  onSelect={(period: string) => { setState({ period, periodIsMonthOrFlexible: [t('mes'), t('flexivel'), t('semana')].includes(period) }); handleDateChange(moment()); }}
                  value={state.period}
                  placeholder={t('periodo')}
                  hideSelected
                  // @ts-ignore
                  disabled={state.isLoading}
                />
              </Box>
              <Box minHeight="55px" minWidth="340px" ml="40px">
                {(state.period === t('dia') || state.period === t('semana')) && (
                  <ContentDate>
                    <Label>{t('data')}</Label>
                    <SingleDatePicker
                      disabled={state.loading}
                      date={moment(state.startDate, 'YYYY-MM-DD')}
                      onDateChange={(data) => handleDateChange(data)}
                      focused={state.focused}
                      onFocusChange={({ focused }) => {
                        state.focused = focused;
                        render();
                      }}
                      id="datepicker"
                      numberOfMonths={1}
                      isOutsideRange={(d) => !d.isBefore(moment())}
                    />
                    <StyledCalendarIcon color="#202370" />
                  </ContentDate>
                )}
                {state.period === t('mes') && (
                  <ContentDate>
                    <Label>{t('data')}</Label>
                    <DatePicker
                      maxDate={moment().toDate()}
                      disabled={state.loading}
                      selected={moment(state.startDate, 'YYYY-MM-DD')
                        .startOf('month')
                        .toDate()}
                      onChange={(d) => handleDateChange(moment(d))}
                      locale="pt-BR"
                      showMonthYearPicker
                      dateFormat="P"
                    />
                    <StyledCalendarIcon color="#202370" />
                  </ContentDate>
                )}
                {state.period === t('flexivel') && (
                  <ContentDate>
                    <Label>{t('data')}</Label>
                    <br />
                    <DateRangePicker
                      readOnly
                      disabled={state.loading}
                      startDate={moment(state.startDate, 'YYYY-MM-DD')}
                      startDateId="your_unique_start_date_id"
                      endDate={state.endDate ? moment(state.endDate, 'YYYY-MM-DD') : null}
                      endDateId="your_unique_end_date_id"
                      onDatesChange={({ startDate, endDate }) => {
                        setState({
                          startDate: startDate.format('YYYY-MM-DD'),
                          endDate: startDate.format('YYYY-MM-DD') !== state.startDate ? null : endDate.format('YYYY-MM-DD'),
                        });
                      }}
                      onFocusChange={(focused) => {
                        state.focusedInput = focused;
                        render();
                      }}
                      focusedInput={state.focusedInput}
                      noBorder
                      isOutsideRange={(d) => !d.isBefore(moment().endOf('month'))}
                      startDatePlaceholderText={t('dataInicial')}
                      endDatePlaceholderText={t('dataFinal')}
                    />
                    <StyledCalendarIcon color="#202370" />
                  </ContentDate>
                )}
              </Box>
              <Box ml="auto" width="">
                <Card>
                  <Flex alignItems="center">
                    <img
                      src={getStatusIcon(status)}
                      alt={`${status} machine status`}
                      className="disableBorder"
                      style={{
                        padding: '10px 22px 10px 0',
                        borderRight: '2px solid #D9DADB',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '22px',
                      }}
                    >
                      <span>
                        <b>{t('ultimaLeitura')}</b>
                      </span>
                      <span>{state.lastReadingDate}</span>
                    </div>
                  </Flex>
                </Card>
              </Box>
            </Flex>
            <br />
            <h2 style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{t('consumo')}</h2>
            <Card>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '50px',
                    }}
                  >
                    <span
                      style={{
                        color: '#202370',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      {t('idDoDispositivo')}
                    </span>
                    <strong style={{ fontSize: '16px' }}>{unit_id}</strong>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      style={{
                        color: '#202370',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      {t('unidadeDeMedida')}
                    </span>
                    <div>
                      <span style={{ fontSize: '14px' }}>{t('litros')}</span>
                      <ToggleSwitchMini
                        checked={shouldNotDisplayLiters}
                        onClick={() => setShouldNotDisplayLiters(!shouldNotDisplayLiters)}
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                      />
                      <span style={{ fontSize: '14px' }}>{t('metrosCubicos')}</span>
                    </div>
                  </div>
                  {props.installationDate && state.isCurrentMonth ? (
                    <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '15px',
                    }}
                    >
                      <Checkbox size={16} checked={state.showForecast} onClick={() => { state.showForecast = !state.showForecast; render(); }} />
                      {t('previsaoConsumo')}
                    </div>
                  ) : null}
                </div>
                <Flex justifyContent="space-around" alignItems="center">
                  {state.period === t('dia') && (
                  <BtnMonthView
                    variant="primary"
                    onClick={handleMonthView}
                    onMouseEnter={() => setIsMonthBtnHovered(true)}
                    onMouseLeave={() => setIsMonthBtnHovered(false)}
                  >
                    <Flex justifyContent="center" alignItems="center">
                      <StyledCalendarIconMonthViewBtn color={isMonthBtnHovered ? '#fff' : '#202370'} />
                      <MonthViewBtnText style={{ paddingLeft: '6px' }}>{t('visaoMensal')}</MonthViewBtnText>
                    </Flex>
                  </BtnMonthView>
                  )}
                  <span style={{ fontWeight: 600, color: '#3c3c3c' }}>
                    {moment(state.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                    <span style={{ padding: '0 4px' }}>{t('a')}</span>
                    {moment(state.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                  </span>
                </Flex>
              </div>
              <Flex
                flexWrap="wrap"
                justifyContent="space-around"
                alignItems="center"
                flexDirection="column"
                mt={50}
                mb={50}
              >
                <Box width={950} height={300} marginTop={10} marginRight={215}>
                  <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                      width={950}
                      height={200}
                      data={defineGraphData()}
                      margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 11,
                      }}
                    >
                      <XAxis
                        dataKey={getGraphDataKey()}
                        tick={getGraphTick()}
                        interval={0}
                        xAxisId={0}
                      />
                      <XAxis
                        dataKey={getGraphDataKey()}
                        tick={getGraphTick()}
                        interval={0}
                        xAxisId={1}
                        hide
                      />
                      <YAxis
                        tickFormatter={(num) => (num % 1 === 0 ? num : Number(num).toFixed(2).replace('.', ','))}
                        domain={[0, state.maxUsage]}
                      />
                      <Tooltip content={<CustomizedToolTip />} />
                      <Bar
                        dataKey={state.period === t('dia') ? 'dayUsage' : 'usage'}
                        barSize={20}
                        fill="#2D81FF"
                        radius={[4, 4, 0, 0]}
                        onClick={handleBarClick}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={setCursor()}
                        xAxisId={1}
                      >
                        {
                          defineGraphData()?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.estimatedUsage ? '#BCD5FB' : '#2D81FF'} />
                          ))
                          }
                      </Bar>
                      {state.periodIsMonthOrFlexible && state.showForecast && state.forecast && state.isCurrentMonth ? (
                        <Bar
                          data={shouldNotDisplayLiters ? state.cubicMetersGraphDataForecast : state.graphDataForecast}
                          dataKey="usage"
                          barSize={20}
                          fill="#E6E6E6"
                          radius={[4, 4, 0, 0]}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          style={setCursor()}
                          xAxisId={0}

                        />
                      ) : null}

                      <Legend
                        align="left"
                        height={20}
                        left={50}
                        layout="vertical"
                        verticalAlign="middle"
                        content={renderLegendText}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </Box>

                <Flex alignItems="center" justifyContent="center" width={950} mt={3}>
                  <Flex mr={2} alignItems="center" data-tip data-for="real-legend" justifyContent="space-between">
                    <div style={{
                      backgroundColor: '#2D81FF', borderRadius: '5px', width: '40px', height: '15px', display: 'inline-block',
                    }}
                    />
                    <span style={{ marginLeft: 4 }}>{t('consumoReal')}</span>
                  </Flex>
                  {!unit_id.startsWith('DMA') && (
                  <Flex ml={2} alignItems="center" data-tip data-for="mean-legend" justifyContent="space-between">
                    <div style={{
                      backgroundColor: '#BCD5FB', borderRadius: '5px', width: '40px', height: '15px', display: 'inline-block',
                    }}
                    />
                    <span style={{ marginLeft: 4 }}>{t('consumoVirtual')}</span>
                  </Flex>
                  )}
                  {(state.periodIsMonthOrFlexible && props.installationDate && state.isCurrentMonth) && (
                    <Flex ml={2} alignItems="center" data-tip data-for="forecast-legend" justifyContent="space-between">
                      <div style={{
                        backgroundColor: '#E6E6E6', borderRadius: '5px', width: '40px', height: '15px', display: 'inline-block',
                      }}
                      />
                      <span style={{ marginLeft: 4 }}>{t('consumoPrevisto')}</span>
                    </Flex>
                  )}
                  <ReactTooltip
                    id="forecast-legend"
                    place="top"
                    effect="solid"
                    delayHide={100}
                    offset={{ top: 0, left: 10 }}
                    textColor="#000000"
                    backgroundColor="rgba(255, 255, 255, 0.97)"
                    border
                    borderColor="#202370"
                  >
                    <span style={{ marginTop: '6px', fontSize: '95%' }}>
                      {t('consumoEstimadoBaseadoHistoricoConsumoUnidade')}
                    </span>
                  </ReactTooltip>
                  <ReactTooltip
                    id="mean-legend"
                    place="top"
                    effect="solid"
                    delayHide={100}
                    offset={{ top: 0, left: 10 }}
                    textColor="#000000"
                    backgroundColor="rgba(255, 255, 255, 0.97)"
                    border
                    borderColor="#202370"
                  >
                    <span style={{ marginTop: '6px', fontSize: '95%' }}>
                      {t('consumoAcumuladoDistribuidoIntervaloSelecionado')}
                    </span>
                  </ReactTooltip>
                  <ReactTooltip
                    id="real-legend"
                    place="top"
                    effect="solid"
                    delayHide={100}
                    offset={{ top: 0, left: 10 }}
                    textColor="#000000"
                    backgroundColor="rgba(255, 255, 255, 0.97)"
                    border
                    borderColor="#202370"
                  >
                    <span style={{ marginTop: '6px', fontSize: '95%' }}>
                      {t('consumoRegistradoPeloDispositivoDurantePeriodoSelecionado')}
                    </span>
                  </ReactTooltip>
                </Flex>

              </Flex>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  marginTop: '20px',
                }}
              >
                <VarContainer>
                  <VarName>{t('consumoPeriodo')}</VarName>
                  <div>
                    <VarValue>
                      {shouldNotDisplayLiters
                        ? (Number(state.period_usage) / 1000).toLocaleString(
                          'pt-BR',
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )
                        : Number(state.period_usage)
                          .toLocaleString('pt-BR', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                    </VarValue>
                    <VarUnit>
                      {getMeasureUnit()}
                    </VarUnit>
                  </div>
                </VarContainer>
                {state.period !== t('dia') && (
                  <>
                    <VarContainer data-tip data-for="forecast">
                      <VarName>{t('mediaConsumoDiario')}</VarName>
                      <div>
                        <VarValue>
                          {formatConsumption(state.daily_average)}
                        </VarValue>
                        <VarUnit>
                          {getMeasureUnit()}
                        </VarUnit>
                      </div>
                    </VarContainer>
                    {state.isCurrentMonth && (
                      <VarContainer>
                        <VarName>{t('previsto')}</VarName>
                        <div>
                          <VarValue>
                            {formatConsumption(state.predicted)}
                          </VarValue>
                          <VarUnit>
                            {getMeasureUnit()}
                          </VarUnit>
                        </div>
                      </VarContainer>
                    )}
                  </>
                )}
              </div>
              <div>
                {state.periodIsMonthOrFlexible && (
                <ReactTooltip
                  id="forecast"
                  place="top"
                  effect="solid"
                  delayHide={100}
                  offset={{ top: 0, left: 10 }}
                  textColor="#000000"
                  backgroundColor="rgba(255, 255, 255, 0.97)"
                  border
                  borderColor="#202370"
                >
                  <span style={{ marginTop: '6px', fontSize: '95%' }}>
                    <strong style={{ fontSize: '1.2rem' }}>
                      {t('mediasDeConsumo')}
                    </strong>
                    {state.consumptionByDayOfWeek.map((e) => (
                      <Flex key={e.label} alignItems="center" justifyContent="space-between" style={{ fontSize: '0.9rem' }}>
                        <strong>
                          {`${e.label}:`}
                          &nbsp;
                          &nbsp;
                        </strong>
                        <div>
                          <strong>
                            {`${shouldNotDisplayLiters ? ((Number(e.consumption) / (e.days || 1)) as number / 1000).toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) : (Number(e.consumption) / (e.days || 1)).toFixed(0)}`}
                          </strong>
                          {getMeasureUnit()}
                        </div>
                      </Flex>
                    ))}

                  </span>
                </ReactTooltip>
                )}
              </div>

            </Card>
          </Box>
        </>
      )}
    </div>
  );
}
