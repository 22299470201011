import { useState, useMemo, useEffect } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Button, Input, Select } from 'components';
import parseDecimalNumber from 'helpers/parseDecimalNumber';
import { useStateVar } from 'helpers/useStateVar';
import api from 'providers';
import { SelectMultiple } from '~/components/NewSelectMultiple';
import { GetUsersListType } from '~/metadata/GetUsersList';

import { FormEditExtras } from './UnitExtras';

export const FormEditClass = ({
  clientId, onSuccess, onCancel, classInfo, unitsList,
}): JSX.Element => {
  const [state, render, setState] = useStateVar({
    submitting: false,
    editingExtras: false,
    selectedUnits: [] as { UNIT_NAME: string, UNIT_ID: number }[],
  });
  const [formData] = useState({
    // @ts-ignore
    CLASS_ID: (classInfo && classInfo.CLASS_ID) || null,
    // @ts-ignore
    CLASS_TYPE: (classInfo && classInfo.CLASS_TYPE) || '',
    // @ts-ignore
    CLASS_NAME: (classInfo && classInfo.CLASS_NAME) || '',
  });
  const isEdit = !!formData.CLASS_ID;

  async function getClassUnits() {
    const { list: oldClassUnitsList } = await api['/clients/get-class-units']({ CLASS_ID: formData.CLASS_ID, CLIENT_ID: clientId });
    const oldClassUnitsIds = oldClassUnitsList.map((unit) => unit.UNIT_ID);
    const oldClassUnits = unitsList.filter((unit) => oldClassUnitsIds.includes(unit.UNIT_ID));
    setState({ selectedUnits: oldClassUnits });
  }

  useEffect(() => {
    getClassUnits();
  }, []);

  async function confirm() {
    let response: ({ CLASS_ID: number} | null) = null;
    let action = null;
    try {
      state.submitting = true; render();
      const reqData = {
        CLASS_ID: formData.CLASS_ID || undefined,
        CLIENT_ID: clientId,
        CLASS_TYPE: formData.CLASS_TYPE || null,
        CLASS_NAME: formData.CLASS_NAME || null,
      };
      if (isEdit) {
        // @ts-ignore
        response = await api['/clients/edit-client-class'](reqData);
        const unitIdsList = state.selectedUnits.map(({ UNIT_ID }) => UNIT_ID);
        await api['/clients/set-class-units']({ UNIT_IDS: unitIdsList, CLASS_ID: reqData.CLASS_ID, CLIENT_ID: clientId });
        // @ts-ignore
        action = 'edit';
      } else {
        // @ts-ignore
        response = await api['/clients/add-client-class'](reqData);
        const unitIdsList = state.selectedUnits.map(({ UNIT_ID }) => UNIT_ID);
        await api['/clients/set-class-units']({ UNIT_IDS: unitIdsList, CLASS_ID: response?.CLASS_ID, CLIENT_ID: clientId });
        // @ts-ignore
        action = 'new';
      }
    } catch (err) {
      console.log(err);
      toast.error('Houve erro');
    }
    state.submitting = false; render();
    if (response && action) onSuccess({ item: response, action });
  }

  // function onStateSelected(item) {
  //   formData.state_item = item;
  //   // @ts-ignore
  //   const selectedState = formData.state_item && formData.state_item.STATE_ID;
  //   // @ts-ignore
  //   if (formData.city_item && formData.city_item.STATE_ID !== selectedState) {
  //     formData.city_item = null;
  //   }
  //   render();
  // }

  // if (formData.city_item && !formData.state_item) {
  //   // @ts-ignore
  //   formData.state_item = states.find((item) => item.STATE_ID === formData.city_item.STATE_ID);
  // }

  // const filtCities = useMemo(() => {
  //   if (!formData.state_item) return cities;
  //   // @ts-ignore
  //   return cities.filter((city) => city.STATE_ID === formData.state_item.STATE_ID);
  // }, [formData.state_item, cities]);

  return (
    <div>
      <Input
        type="text"
        value={formData.CLASS_TYPE}
        placeholder="Tipo da Classe"
        onChange={(event) => { formData.CLASS_TYPE = event.target.value; render(); }}
      />
      <div style={{ paddingTop: '10px' }} />
      <Input
        type="text"
        value={formData.CLASS_NAME}
        placeholder="Nome da Classe"
        onChange={(event) => { formData.CLASS_NAME = event.target.value; render(); }}
      />
      <div style={{ paddingTop: '10px' }} />
      <SelectMultiple
        options={unitsList}
        propLabel="UNIT_NAME"
        values={state.selectedUnits}
        // error={extraErrors.clients}
        label="Unidades"
        placeholder="Selecione as unidades deste grupo"
        onSelect={
          (item, list, newValues) => {
            setState({ selectedUnits: newValues });
          }
        }
      />
      <div style={{ paddingTop: '10px' }} />
      {(isEdit)
    && (
    <div style={{ paddingTop: '10px' }}>
      <FakeLink onClick={() => setState({ editingExtras: true })}>Metadados</FakeLink>
    </div>
    )}
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '30px',
      }}
      >
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <Button style={{ width: '140px' }} onClick={confirm} variant="primary">
          {isEdit ? 'Salvar' : 'Adicionar'}
        </Button>
        {/* @ts-ignore */}
        <Button style={{ width: '140px', margin: '0 20px' }} onClick={onCancel} variant="grey">
          Cancelar
        </Button>
      </div>
    </div>
  );
};

const FakeLink = styled.span`
  color: black;
  text-decoration: underline;
  cursor: pointer;
`;
