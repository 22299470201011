import { InfoIcon, WarnIcon } from '~/icons';
import {
  ShowProblemDataWrapper, NoInfoTooltip, UnitLink, NoInfoIcon,
} from './styles';
import ReactTooltip from 'react-tooltip';
import { t } from 'i18next';
import { getUserProfile } from '~/helpers/userProfile';
import { formatNumber } from '~/helpers/formatNumber';

type ShowProblemDataProps = {
  value: string | number;
  tooltipId: string;
};

export const controlColumnsConfigs = [
  {
    id: 'clientName',
    visible: true,
    label: t('cliente'),
  },
  {
    id: 'stateName',
    visible: true,
    label: t('estado'),
  },
  {
    id: 'cityName',
    visible: true,
    label: t('cidade'),
  },
  {
    id: 'unitName',
    visible: true,
    label: t('unidade'),
  },
  {
    id: 'consumption',
    visible: true,
    label: `${t('consumo')} (kWh)`,
  },
  {
    id: 'totalCharged',
    visible: true,
    label: `${t('consumo')} (R$)`,
  },
  {
    id: 'refrigerationConsumption',
    visible: true,
    label: t('consumoRefrigecao'),
  },
  {
    id: 'refrigerationConsumptionPercentage',
    visible: true,
    label: t('refrigecacaoConsumo'),
  },
  {
    id: 'consumptionByArea',
    visible: true,
    label: `${t('consumoPorM')} (kWh/m)²`,
  },
  {
    id: 'refCapacity',
    visible: true,
    label: t('capacidadeRefrigeracao'),
  },
  {
    id: 'refrigerationConsumptionByArea',
    visible: true,
    label: `m²/TR ${t('instalado')}`,
  },
];

const formatValue = (value, sufix) => (value === null || value === undefined ? '-' : `${formatNumber(value)}${sufix}`);
const formatValuePrefix = (value, prefix) => (value === null || value === undefined ? '-' : `${prefix}${formatNumber(value)}`);

export const ShowProblemData: React.FC<ShowProblemDataProps> = ({ value, tooltipId }) => {
  const profile = getUserProfile();
  const isAdmin = profile.permissions?.isAdminSistema;

  const Icon = isAdmin ? WarnIcon : InfoIcon;
  const message = isAdmin ? 'dadoIncoerente' : 'naoFoiPossivelColetarDado';
  const description = isAdmin ? 'dadoIncoerenteDesc' : 'paraMaisDetalhes';

  return (
    <ShowProblemDataWrapper>
      {isAdmin && value}
      <Icon data-tip={tooltipId} data-for={`tooltip-problem-data-${tooltipId}`} />
      <ReactTooltip
        id={`tooltip-problem-data-${tooltipId}`}
        place="top"
        effect="solid"
      >
        <NoInfoTooltip>
          <Icon color="#FFFFFF" width="20" height="17" />
          <div>
            <span>{t(message)}</span>
            <p>{t(description)}</p>
          </div>
        </NoInfoTooltip>
      </ReactTooltip>
    </ShowProblemDataWrapper>
  );
};

export const StringLabel = (value, tooltipId, padding?, fontSize?) => {
  const haveInfo = value !== null && value !== undefined && value !== '';

  if (haveInfo) {
    return (
      <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
        {value}
      </div>
    );
  }

  const displayValue = value === null ? '-' : value;

  return (
    <div style={{ padding: '16px 0' }}>
      <ShowProblemData value={displayValue} tooltipId={tooltipId} />
    </div>
  );
};

export const LinkedStringLabel = (value, unitId, tooltipId, padding?, fontSize?) => {
  const haveInfo = value !== null && value !== undefined && value !== '';

  if (haveInfo) {
    return (
      <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
        <UnitLink
          style={{ color: 'black' }}
          to={`/analise/unidades/energyEfficiency/${unitId}`}
        >
          {value}
        </UnitLink>
      </div>
    );
  }

  const displayValue = value === null ? '-' : value;

  return (
    <div style={{ padding: '16px 0' }}>
      <ShowProblemData value={displayValue} tooltipId={tooltipId} />
    </div>
  );
};

export const ValueLabelWithSufix = (value, sufix, tooltipId, padding?, fontSize?) => {
  const haveInfo = value !== null && value !== undefined;
  const isPositiveValue = value > 0;
  const displayValue = formatValue(value, sufix);

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      {haveInfo && isPositiveValue ? displayValue : <ShowProblemData value={displayValue} tooltipId={tooltipId} />}
    </div>
  );
};

export const ValueLabelWithSufixNullable = (value, sufix, tooltipId, padding?, fontSize?) => {
  const nullableData = value === null || value === undefined;
  const isPositiveValue = value > 0;
  const displayValue = formatValue(value, sufix);

  if (nullableData) { return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>-</div>
  ); }

  return (
    <div style={{ padding: padding || '16px 0', fontSize: fontSize || '12px' }}>
      {isPositiveValue ? displayValue : <ShowProblemData value={displayValue} tooltipId={tooltipId} />}
    </div>
  );
};

export const ValueLabelWithPrefixNullable = (value, prefix, tooltipId) => {
  const nullableData = value === null || value === undefined;
  const isPositiveValue = value > 0;
  const displayValue = formatValuePrefix(value, prefix);

  if (nullableData) { return (
    <div style={{ padding: '16px 0' }}>-</div>
  ); }

  return (
    <div style={{ padding: '16px 0' }}>
      {isPositiveValue ? displayValue : <ShowProblemData value={displayValue} tooltipId={tooltipId} />}
    </div>
  );
};

export const PercentageTableLabel = (value, tooltipId) => {
  const haveInfo = value !== null && value !== undefined;
  const percentageInRange = Number(value) <= 100 && Number(value) >= 0;

  if (haveInfo && percentageInRange) {
    return (
      <div style={{ padding: '16px 0' }}>
        {formatValue(value, '%')}
      </div>
    );
  }

  const displayValue = value === null ? '-' : formatValue(value, '%');

  return (
    <div style={{ padding: '16px 0' }}>
      <ShowProblemData value={displayValue} tooltipId={tooltipId} />
    </div>
  );
};
