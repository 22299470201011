import { BackgroundCardItem, BorderSpan, NameCardItem } from '../WaterCard/style';

type TTabOptionCard = {
  arrayItems: {
    selected: boolean
    name: string
    onClickFunc: () => void,
  }[]
}

export function TabOptionCard({ arrayItems }: Readonly<TTabOptionCard>) {
  return (
    <>
      {
        arrayItems.map((item) => (
          <>
            <BackgroundCardItem>
              <span
                style={{
                  backgroundColor: item.selected
                    ? '#f4f4f4'
                    : 'transparent',
                }}
              />
              <span />
            </BackgroundCardItem>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '150px 150px auto',
                marginBottom: '10px',
              }}
            >
              <NameCardItem
                style={{
                  borderBottom: item.selected
                    ? '1px solid lightgrey'
                    : 'none',
                  backgroundColor: item.selected
                    ? '#f4f4f4'
                    : 'transparent',
                  cursor: item.selected ? 'pointer' : undefined,
                }}
                onClick={() => item.onClickFunc}
              >
                {item.name}
              </NameCardItem>
              <BorderSpan />
              <BorderSpan />
            </div>
          </>
        ))
      }
    </>
  );
}
